/* src/pages/DesignSystem/primitives/GlassPanel/GlassPanel.css */
.glass-panel {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: var(--radius-lg);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.glass-panel--light {
  background: rgba(255, 255, 255, 0.4);
}

.glass-panel--dark {
  background: rgba(0, 0, 0, 0.2);
}

.glass-panel--blur-sm {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.glass-panel--blur-md {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.glass-panel--blur-lg {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}