/* Animation Styles */
.transitions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.transition-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.transition-preview {
  height: 100px;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-sm);
  overflow: hidden;
}

.animated-box {
  width: 50px;
  height: 50px;
  background: var(--primary-color);
  border-radius: var(--radius-sm);
  transition: transform 0.3s ease;
}

.transition-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.transition-name {
  font-family: var(--font-family-mono);
  color: var(--text-primary);
}

.transition-details {
  display: flex;
  gap: var(--spacing-md);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.transition-description {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Timing Functions */
.timing-functions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.timing-function-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.timing-preview {
  height: 100px;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  position: relative;
  margin-bottom: var(--spacing-sm);
  overflow: hidden;
}

.animated-dot {
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.timing-function-item:hover .animated-dot {
  transform: translateY(-50%) translateX(calc(100% + 200px));
}

.timing-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.timing-name {
  font-family: var(--font-family-mono);
  color: var(--text-primary);
}

.timing-value {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-mono);
}

.timing-description {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Animation Examples */
.animation-examples {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.demo-button {
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: transform var(--transition-fast);
}

.demo-button:hover {
  transform: scale(1.05);
}

.demo-card {
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  height: 100px;
  overflow: hidden;
  cursor: pointer;
}

.demo-card-content {
  height: 100%;
  background: var(--primary-color);
  transform: translateY(70%);
  transition: transform var(--transition-normal);
}

.demo-card:hover .demo-card-content {
  transform: translateY(0);
}

.demo-bounce {
  height: 100px;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-md);
}

.demo-bounce-item {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 50%;
  transition: transform var(--transition-bounce);
}

.demo-bounce:hover .demo-bounce-item {
  transform: translateX(200px);
}

.animation-label {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .transitions-grid,
  .timing-functions-grid {
    grid-template-columns: 1fr;
  }
}



/* Animation spécifique pour chaque type de transition */
.transition-item[data-transition="transition-fast"]:hover .animated-box {
  transform: scale(1.1);
  transition: transform var(--duration-200) var(--transition-timing-smooth);
}

.transition-item[data-transition="transition-normal"]:hover .animated-box {
  transform: translateY(-20px);
  transition: transform var(--duration-300) var(--transition-timing-smooth);
}

.transition-item[data-transition="transition-slow"]:hover .animated-box {
  transform: rotate(180deg);
  transition: transform var(--duration-500) var(--transition-timing-smooth);
}

.transition-item[data-transition="transition-bounce"]:hover .animated-box {
  transform: translateY(-20px) scale(1.1);
  transition: transform var(--duration-300) var(--transition-timing-bounce);
}

.transition-item[data-transition="transition-spring"]:hover .animated-box {
  transform: rotate(360deg) scale(1.2);
  transition: transform var(--duration-300) var(--transition-timing-spring);
} 