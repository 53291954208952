/* Conteneur pour tous les messages */
.status-messages-container {
  position: fixed;
  top: var(--spacing-xl);
  right: var(--spacing-xl);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.status-message {
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-md);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: var(--font-size-sm);
  font-weight: 500;
  min-width: 300px;
  max-width: 400px;
  animation: slideIn 0.3s ease-out forwards;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.status-message.removing {
  animation: slideOut 0.3s ease-in forwards;
}

.status-message.success {
  background-color: var(--success-color, #4CAF50);
  color: white;
}

.status-message.error {
  background-color: var(--error-color, #f44336);
  color: white;
}

.status-message.info {
  background-color: var(--info-color, #2196F3);
  color: white;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
} 