.search-bar-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.search-bar-input {
  /* Styles spécifiques pour l'input de recherche */
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Style du bouton de reset (la croix) du type="search" */
.search-bar-input input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: var(--size-4);
  width: var(--size-4);
  background: var(--text-secondary);
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  cursor: pointer;
}

/* Style du placeholder */
.search-bar-input input::placeholder {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

/* Ajustement de l'icône de recherche */
.search-bar-input .input-icon {
  color: var(--text-secondary);
} 