.multi-price-input-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    width: 100%;
  }
  
  .multi-price-input-label {
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
  }
  
  .required-mark {
    color: var(--secondary-color);
    margin-left: var(--spacing-xs);
  }
  
  /* Style pour chaque section de prix */
  .price-section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    padding: var(--spacing-md);
    background-color: var(--background-secondary);
    border-radius: var(--radius-md);
    border: 1px solid var(--border-color);
    transition: var(--transition-normal);
  }
  
  .price-section:hover {
    border-color: var(--border-color-dark);
  }
  
  /* En-tête de la section prix */
  .price-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-xs);
  }
  
  .price-section-title {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
  }
  
  /* Conteneur des champs de prix */
  .price-fields-container {
    display: flex;
    gap: var(--spacing-sm);
    align-items: flex-start;
  }
  
  /* Bouton de suppression */
  .delete-price-button {
    color: var(--text-tertiary);
    transition: var(--transition-fast);
  }
  
  .delete-price-button:hover {
    color: var(--secondary-color);
  }
  
  /* Bouton d'ajout de prix */
  .add-price-button {
    margin-top: var(--spacing-xs);
    align-self: flex-start;
  }
  
  /* Messages d'aide et d'erreur */
  .multi-price-input-helper {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
  }
  
  .multi-price-input-error {
    font-size: var(--font-size-xs);
    color: var(--secondary-color);
    font-weight: var(--font-weight-medium);
  }
  
  /* Animation d'accordéon */
  .price-section-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .price-section-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms, transform 200ms;
  }
  
  .price-section-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .price-section-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 200ms, transform 200ms;
  }