.notification-bubble {
  position: fixed;
  bottom: var(--spacing-lg);
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-color);
  color: var(--text-on-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-full);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  box-shadow: var(--shadow-md);
  z-index: 1000;
  opacity: 0;
  transition: opacity var(--transition-normal);
  pointer-events: none;
}

.notification-bubble.show {
  opacity: 1;
}

/* Variantes */
.notification-bubble.success {
  background: var(--primary-color);
}

.notification-bubble.info {
  background: var(--info-color, #2196F3);
}

.notification-bubble.error {
  background: var(--secondary-color);
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, var(--spacing-md));
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.notification-bubble.show {
  animation: fadeInUp var(--transition-normal) forwards;
}

/* Responsive */
@media (max-width: 768px) {
  .notification-bubble {
    max-width: 90%;
    text-align: center;
  }
} 