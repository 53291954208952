/* Radius Showcase Styles */
.radius-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.radius-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.radius-preview {
  height: 100px;
  background: var(--primary-color);
  margin-bottom: var(--spacing-sm);
  position: relative;
  overflow: hidden;
}

.radius-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.radius-name {
  font-family: var(--font-family-mono);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.radius-value {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-mono);
}

.radius-usage {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

/* Exemples d'utilisation */
.radius-examples {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-md);
}

.radius-example {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.radius-example-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.radius-example-header h4 {
  margin: 0;
  color: var(--text-primary);
}

.radius-example-header code {
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
}

.radius-example-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
}

/* Styles spécifiques pour chaque exemple */
[data-example="sm"] .radius-example-shape {
  width: 60px;
  height: 30px;
  background: var(--primary-color);
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-size-sm);
}

/* Style pour le tag */
[data-example="full"][data-type="tag"] .radius-example-shape {
  width: 60px;
  height: 30px;
  background: var(--primary-color);
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-size-sm);
}

[data-example="md"] .radius-example-shape {
  width: 120px;
  height: 40px;
  background: var(--primary-color);
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

[data-example="lg"] .radius-example-shape {
  width: 200px;
  height: 120px;
  background: var(--primary-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  color: white;
}

[data-example="xl"] .radius-example-shape {
  width: 100%;
  height: 140px;
  background: var(--primary-color);
  border-radius: var(--radius-xl);
  padding: var(--spacing-md);
  color: white;
}

/* Style pour l'avatar */
[data-example="full"][data-type="avatar"] .radius-example-shape {
  width: 80px;
  height: 80px;
  background: var(--primary-color);
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-size-xl);
}

/* Styles spécifiques pour chaque type de rayon */
[data-radius="sm"] .radius-preview {
  border-radius: var(--radius-sm);
}

[data-radius="md"] .radius-preview {
  border-radius: var(--radius-md);
}

[data-radius="lg"] .radius-preview {
  border-radius: var(--radius-lg);
}

[data-radius="xl"] .radius-preview {
  border-radius: var(--radius-xl);
}

[data-radius="full"] .radius-preview {
  border-radius: var(--radius-full);
}
  