.datetime-filter {
  display: flex;
  flex-direction: column;
}

/* Style du bouton avec le nouveau namespace */
.datetime-filter__button {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: all var(--transition-fast);
  font-family: inherit;
}

.datetime-filter__button.selected .tag {
  background: var(--tertiary-color);
  color: var(--text-on-primary);
}

.datetime-filter__section {
  display: flex;
  padding-bottom: var(--spacing-sm);
  flex-direction: column;
  width: 100%;
}

/* Wrapper pour gérer l'overflow et le dégradé */
.datetime-filter__group-wrapper {
  position: relative;
  width: 100%;
}

/* Dégradé gauche */
.datetime-filter__group-wrapper:has(.datetime-filter__group.scrolled)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 48px;
  background: linear-gradient(to left, 
    rgba(242, 233, 219, 0),
    rgba(242, 233, 219, 1) 90%
  );
  pointer-events: none;
  z-index: 1;
  opacity: 1;
  transition: opacity var(--transition-fast);
}

/* Dégradé droit */
.datetime-filter__group-wrapper::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 48px;
  background: linear-gradient(to right, 
    rgba(242, 233, 219, 0),
    rgba(242, 233, 219, 1) 90%
  );
  pointer-events: none;
  z-index: 1;
  opacity: 1;
  transition: opacity var(--transition-fast);
}

/* Cacher le dégradé droit à la fin */
.datetime-filter__group-wrapper:has(.datetime-filter__group.at-end)::after {
  opacity: 0;
}

/* Cacher le dégradé gauche au début */
.datetime-filter__group-wrapper:has(.datetime-filter__group:not(.scrolled))::before {
  opacity: 0;
}

.datetime-filter__group {
  display: flex;
  gap: var(--spacing-xs);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-wrap: nowrap;
  width: 100%;
  padding: var(--spacing-xs) 0;
  margin: calc(-1 * var(--spacing-xs)) 0;
}

.datetime-filter__group::-webkit-scrollbar {
  display: none;
}

/* DatePicker styles */
.datetime-filter__picker-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal-backdrop);
  animation: fadeIn var(--transition-fast);
}

.datetime-filter__picker-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-color);
  padding: var(--spacing-xl);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  z-index: var(--z-modal);
  animation: slideIn var(--transition-fast);
}

.datetime-filter__picker-close {
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  border-radius: var(--radius-full);
  cursor: pointer;
  color: var(--text-secondary);
  transition: all var(--transition-fast);
}

.datetime-filter__picker-close:hover {
  background: var(--background-secondary);
  color: var(--text-primary);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .datetime-filter {
    flex-direction: column;
    align-items: stretch;
  }

  .datetime-filter__section {
    width: 100%;
  }

  .datetime-filter__group {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.datetime-filter__time-icon {
  margin-right: 8px;
  vertical-align: middle;
}