

/* Guidelines */
.guidelines-list {
    background: var(--background-secondary);
    border-radius: var(--radius-lg);
    padding: var(--spacing-lg);
    margin-top: var(--spacing-md);
    border: 1px solid var(--border-color);
  }
  
  .guidelines-list li {
    color: var(--text-secondary);
    margin-bottom: var(--spacing-sm);
    line-height: 1.6;
    position: relative;
    padding-left: var(--spacing-lg);
    background: var(--background-color);
    padding: var(--spacing-sm) var(--spacing-lg);
    border-radius: var(--radius-md);
    margin-bottom: var(--spacing-sm);
  }
  
  .guidelines-list li::before {
    content: "•";
    color: var(--primary-color);
    position: absolute;
    left: 0;
    font-weight: bold;
  }