.monkey-cursor-wrapper {
  position: relative;
  
  display: inline-flex;  /* au lieu de flex */
}

.monkey-cursor {
  position: absolute;
  top: calc(100% - var(--offset-y)); /* Le singe sera positionné sur la "ligne de base" */
  transform: translateY(-100%); /* Remonte le singe de sa propre hauteur */
  z-index: 0; /* On utilise -1 pour être sûr d'être derrière les éléments */
  opacity: 0.75;
}

.monkey-cursor--left {
  left: var(--offset-x);
}

.monkey-cursor--right {
  right: var(--offset-x);
}

/* Tailles du singe */
.monkey-cursor--size-sm {
  --monkey-size: 44px;
}

.monkey-cursor--size-md {
  --monkey-size: 54px;
}

.monkey-cursor--size-lg {
  --monkey-size: 64px;
}

/* Le PNG prendra la taille définie */
.monkey-cursor img {
 
  height: var(--monkey-size);
  object-fit: contain;
}

/* Contrôle de la direction du regard */
.monkey-cursor--look-right {
  transform: translateY(-100%);
}

.monkey-cursor--look-left {
  transform: translateY(-100%) scaleX(-1);
}

/* Pour les écrans très petits */
@media (max-width: 340px) {
  .monkey-cursor {
    --offset-x: 4px; /* Réduit l'espacement sur petits écrans */
  }
  
  /* Réduire les tailles sur mobile */
  .monkey-cursor--size-md {
    --monkey-size: 24px;
  }
  
  .monkey-cursor--size-lg {
    --monkey-size: 32px;
  }
}

/* Ajoutons un z-index plus bas pour le background si nécessaire */
.input-container {
  position: relative;
  z-index: 0;
} 