.icons-showcase {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.icon-type-container {
  padding: var(--spacing-lg);
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
}

.icon-group {
  margin-bottom: var(--spacing-lg);
}

.icon-group h4 {
  margin: 0 0 var(--spacing-xs);
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.icon-description {
  margin: 0 0 var(--spacing-md);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.icon-preview {
  padding: var(--spacing-lg);
  background: var(--background-color);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.icon-sizes, .icon-colors {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
}

.icon-size, .icon-color {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
}

.size-label, .color-label {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.showcase-info {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  background: var(--background-tertiary);
  border-radius: var(--radius-md);
}

.showcase-name {
  font-family: var(--font-family-mono);
  color: var(--primary-color);
  font-size: var(--font-size-sm);
}

.showcase-description {
  margin: var(--spacing-xs) 0;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.showcase-usage {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-style: italic;
}

.showcase-code {
  margin-top: var(--spacing-sm);
  padding: var(--spacing-sm);
  background: var(--background-color);
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-color);
}

.showcase-code code {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.properties-table {
  margin: var(--spacing-md) 0;
  overflow-x: auto;
}

.properties-table table {
  width: 100%;
  border-collapse: collapse;
  background: var(--background-color);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
}

.properties-table th,
.properties-table td {
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.properties-table th {
  background: var(--background-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.properties-table td {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.component-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

@media (max-width: 768px) {
  .icon-preview {
    padding: var(--spacing-md);
  }

  .icon-type-container {
    padding: var(--spacing-md);
  }

  .component-grid {
    grid-template-columns: 1fr;
  }
} 