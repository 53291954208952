/* ===== RADIO BUTTONS ===== */
.custom-radio-group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  /* Responsive - Mobile */
  @media (max-width: 768px) {
    .custom-radio-group {
        flex-direction: column;
        width: 100%;
    }

    .custom-radio-container {
        width: 100%;
    }
  }
  
  .custom-radio-option {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .custom-radio-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    cursor: pointer;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--radius-md);
    transition: all var(--transition-normal);
    user-select: none;
  }
  
  .custom-radio-content {
    padding-left: calc(20px + var(--spacing-sm) * 3); /* Aligne avec le texte de l'option */
    margin-top: var(--spacing-xs);
  }
  
  /* Effet hover sur tout le conteneur */
  .custom-radio-container:hover {
    background-color: var(--neutral-light);
  }
  
  .custom-radio-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-radio-mark {
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid var(--neutral-color);
    border-radius: var(--radius-full);
    transition: all var(--transition-normal);
    background-color: white;
  }
  
  /* Le cercle intérieur qui apparaît au clic */
  .custom-radio-mark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
    border-radius: var(--radius-full);
    transform: translate(-50%, -50%) scale(0);
    transition: transform var(--transition-spring);
  }
  
  /* État checked - Affichage du cercle intérieur */
  .custom-radio-container input[type="radio"]:checked + .custom-radio-mark::after {
    transform: translate(-50%, -50%) scale(1);
  }
  
  /* État checked - Bordure */
  .custom-radio-container input[type="radio"]:checked + .custom-radio-mark {
    border-color: var(--primary-color);
  }
  
  /* État hover sur le cercle */
  .custom-radio-container:hover .custom-radio-mark {
    border-color: var(--primary-light);
  }
  
  /* État focus */
  .custom-radio-container input[type="radio"]:focus + .custom-radio-mark {
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.2);
  }
  
  /* État disabled */
  .custom-radio-container input[type="radio"]:disabled + .custom-radio-mark {
    border-color: var(--neutral-color);
    background-color: var(--neutral-light);
    cursor: not-allowed;
  }
  
  .custom-radio-container input[type="radio"]:disabled ~ .custom-radio-label {
    color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  /* Label du radio */
  .custom-radio-label {
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    user-select: none;
  }