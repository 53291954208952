.offline-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background-color);
  padding: var(--spacing-md);
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  font-family: var(--font-family-sans);
}

.offline-message h3 {
  font-family: var(--font-family-headings);
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.offline-message p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-sm);
}

.retry-button {
  margin-top: var(--spacing-sm);
  padding: var(--button-padding-y) var(--button-padding-x);
  border: none;
  border-radius: var(--button-border-radius);
  background: var(--primary-color);
  color: var(--text-on-primary);
  font-family: var(--font-family-sans);
  font-size: var(--button-font-size);
  cursor: pointer;
  transition: transform var(--transition-normal) var(--transition-timing-bounce);
}

.retry-button:hover {
  transform: scale(var(--scale-hover));
}

.retry-button:active {
  transform: scale(var(--scale-active));
} 