/* FilterSection */
.filter-section-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.filter-section-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin: 0;
}

.filter-section-title svg {
  color: var(--color-primary);
}

.filter-section-title span {
  margin-left: var(--spacing-xs);
}

/* Location Filters */
.location-filters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: 0 var(--spacing-xs);
}

/* WHAT section */
.what-filters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: 0 var(--spacing-xs);
}

/* Composants intégrés */
.filter-drawer .search-bar {
  width: 100%;
  padding: var(--spacing-sm);
  border-radius: var(--radius-lg);
  background: var(--background-tertiary);
}

/* Ajustements spécifiques au FilterDrawer */
.filter-drawer .drawer-content {
  padding: 0;
}

/* Ajustement des sections */
.filter-drawer .filter-section {
  margin-bottom: var(--spacing-lg);
}