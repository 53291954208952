@import '../../styles/design-tokens.css';

/* Variables locales pour le composant */
:root {
  --z-modal: 9000;
  --z-modal-content: 10001;
  --z-modal-notification: 10002;
}

/* Structure de base de la modale */
.event-modal {
  position: relative;
  max-width: 90%;
  width: 600px;
  margin: 0 auto;
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.event-modal__content {
  position: relative;
  width: 100%;
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  overflow-y: auto;
  overscroll-behavior: contain;
}

/* Surcharges spécifiques des composants de base */
.event-modal .event-card__header {
  padding: var(--spacing-md);
}

.event-modal .event-card__main {
  padding: 0 var(--spacing-md);
}

.event-modal .event-card__footer {
  padding: 0 var(--spacing-md) var(--spacing-md);
}

/* Styles pour le conteneur de l'image */
.event-card__image {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9; /* Force un ratio d'aspect fixe */
  border-radius: var(--radius-lg);
  overflow: hidden; /* Empêche le débordement */
  background-color: var(--background-tertiary);
}

/* Styles pour l'image elle-même */
.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image couvre tout l'espace */
  object-position: center;
  display: block; /* Élimine l'espace sous l'image */
}

/* Style pour l'image de fallback */
.event-fallback-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Pour le logo, on utilise contain au lieu de cover */
  object-position: center;
  padding: var(--spacing-md);
}

/* Composants spécifiques à la modale */
.event-modal-map {
  width: 50%;
  height: 120px;
  flex-shrink: 0;
  border-radius: var(--radius-md);
  overflow: hidden;
}

.event-modal-map-container {
  width: 100%;
  height: 100%;
}

/* Bouton d'ouverture d'image */
.event-modal  .image-open-button {
  position: absolute;
  bottom: var(--spacing-sm);
  right: var(--spacing-sm);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: var(--radius-full);
  padding: var(--spacing-sm);
  color: var(--color-gray-300);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.7;
  z-index: 1;
}

/* Styles de l'organisateur */
.event-modal-organizer-block {
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.event-modal-organizer-block:hover {
  background-color: var(--background-tertiary);
}

.event-modal-organizer-avatar {
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);
  object-fit: cover;
}

.event-modal-organizer-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  border-left: 2px solid var(--background-secondary);
  padding-left: var(--spacing-md);
}

.event-modal-organizer-name {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
}

.event-modal-organizer-link {
  color: var(--primary-color);
  font-size: var(--font-size-xs);
  transition: color var(--transition-fast);
}

.price-list {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-sm);
  border-left: 2px solid var(--background-secondary);
  padding-left: var(--spacing-md);
}

.event-modal__location-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  flex: 1;
  min-width: 0;
}
.modal-description {
  line-height: var(--line-height-normal);
  color: var(--text-secondary);
  white-space: pre-wrap;
}

/* Gestion des événements */
.event-modal * {
  pointer-events: none;
}

.event-modal .event-card__actions,
.event-modal .event-card__dates-list,
.event-modal .event-card__dates-list--expanded,
.event-modal .event-card__actions *,
.event-modal .directions-button,
.event-modal .image-open-button,
.event-modal .event-card__dates-toggle,
.event-modal .event-modal-organizer-block,
.event-modal .booking-link,
.event-modal .booking-info {
  pointer-events: auto;
}

/* Responsive */
@media (max-width: 768px) {
  .event-modal {
    max-width: 95%;
  }
  
  .event-modal__container {
    padding: var(--spacing-md);
  }
}

/* Optimisations tactiles */
@media (hover: none) {
  .event-modal {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }
} 

.event-modal .leaflet-control-attribution {
  display: none;
}

.event-modal .leaflet-control-attribution a {
  display: none;
}

.event-modal-organizer-avatar-fallback {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
}

/* ... styles existants ... */

.booking-info {
  display: flex;
  align-items: center;
  font-size: var(--font-size-md);
}

.booking-link {
  color: var(--primary-color);
  text-decoration: underline;
  transition: color var(--transition-normal) var(--transition-timing-smooth);
}

.booking-link:hover {
  color: var(--primary-dark);
}

.booking-text {
  color: var(--text-secondary);
}

/* Pour assurer que l'icône et le lien sont bien alignés */
.modal-item-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.modal-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.price-booking-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.event-modal .event-card__location-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.event-modal .event-card__location-name {
  display: block;
  margin-bottom: var(--spacing-xs);
}



