/* Styles pour la présentation des couleurs */
.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xl);
}

.color-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform var(--transition-normal);
}

.color-item:hover {
  transform: translateY(-2px);
}

.color-preview {
  height: 100px;
  width: 100%;
}

.color-info {
  padding: var(--spacing-md);
}

.color-name {
  display: block;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.color-value {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}
