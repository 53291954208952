.profile-edit {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);
}

.profile-edit__header {
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.profile-edit__title {
  font-size: var(--text-xxl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.profile-edit__subtitle {
  font-size: var(--text-md);
  color: var(--text-secondary);
}

.profile-edit__form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.profile-edit__group {
  margin-bottom: var(--spacing-md);
}

.profile-edit__label {
  display: block;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-xs);
  color: var(--text-primary);
}

.profile-edit__label--required::after {
  content: "*";
  color: var(--color-error);
  margin-left: var(--spacing-xs);
}

.profile-edit__label-hint {
  display: inline-block;
  font-weight: var(--font-weight-regular);
  font-size: var(--text-sm);
  color: var(--text-secondary);
  margin-left: var(--spacing-xs);
}

.profile-edit__avatar-preview {
  position: relative;
  width: 150px;
  height: 150px;
  margin-top: var(--spacing-md);
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--color-primary-light);
}

.profile-edit__avatar-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-edit__gallery-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.profile-edit__gallery-item {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--border-light);
}

.profile-edit__gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-edit__gallery-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}

.profile-edit__gallery-remove:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

.profile-edit__upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-sm);
}

.profile-edit__actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xl);
}

.profile-edit__error-message {
  background-color: rgba(255, 0, 0, 0.1);
  color: #d32f2f;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  border-left: 4px solid #d32f2f;
}

@media (max-width: 768px) {
  .profile-edit {
    padding: var(--spacing-md) var(--spacing-sm);
  }
  
  .profile-edit__title {
    font-size: var(--text-xl);
  }
  
  .profile-edit__gallery-preview {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .profile-edit__actions {
    flex-direction: column-reverse;
  }
} 