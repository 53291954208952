.bottom-nav {
  position: var(--bottom-nav-position, fixed);
  bottom: max(env(safe-area-inset-bottom), 1rem);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 500px;
  background: var(--background-tertiary);
  /*color-mix(in srgb, var(--color-beige-300) 95%, transparent);*/
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: var(--radius-xl);
  padding: var(--spacing-sm);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  font-family: inherit;
  transition: transform var(--transition-normal) var(--transition-timing-bounce);
}

.bottom-nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-sm);
  position: relative;
}

/* Séparateurs verticaux */
.nav-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: calc(-1 * var(--spacing-sm) / 2);
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 1px;
  background: color-mix(in srgb, var(--text-secondary) 30%, transparent);
  opacity: 0.8;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
  text-decoration: none;
  transition: all var(--transition-fast);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  flex: 1;
  min-width: 64px;
  background: transparent;
  border: none;
}

.nav-item:active {
  transform: scale(0.95);
  background: transparent;
}

.nav-item-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  color: var(--primary-color);
  transition: color var(--transition-fast);
  overflow: visible;
}

.icon-leaving,
.icon-entering {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Animation de transition */
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Application des animations */
.nav-item[data-view-mode="list"][data-animating="true"] .icon-leaving {
  animation: slideOutRight 0.3s var(--transition-timing-bounce) forwards;
}

.nav-item[data-view-mode="list"][data-animating="true"] .icon-entering {
  animation: slideInLeft 0.3s var(--transition-timing-bounce) forwards;
}

.nav-item[data-view-mode="map"][data-animating="true"] .icon-leaving {
  animation: slideOutLeft 0.3s var(--transition-timing-bounce) forwards;
}

.nav-item[data-view-mode="map"][data-animating="true"] .icon-entering {
  animation: slideInRight 0.3s var(--transition-timing-bounce) forwards;
}

/* Animation bounce pour les autres boutons */
.nav-item:not([data-view-mode]):active .nav-item-icon {
  animation: popAndBounce 0.5s var(--transition-timing-bounce);
}

.nav-item-label {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-sans);
  transition: opacity var(--transition-fast);
  color: var(--text-secondary);
}

.nav-item:hover {
  color: var(--primary-color);
}

.nav-item:hover .nav-item-icon {
  transform: none;
}

.nav-item-badge {
  position: absolute;
  top: -4px;
  right: -8px;
  background: var(--secondary-color);
  color: var(--text-on-secondary);
  font-size: 0.75rem;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  transform: translate(50%, -50%);
  border: 2px solid var(--color-beige-300);
  z-index: 2;
}

/* Support des safe areas iOS */
@supports (padding: max(0px)) {
  .bottom-nav {
    padding-bottom: max(var(--spacing-sm), env(safe-area-inset-bottom));
  }
}

/* Animation d'entrée */
@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.bottom-nav.entering {
  animation: slideUp 0.3s var(--transition-timing-bounce) forwards;
}

/* Adaptation mobile */
@media (max-width: 768px) {
  .bottom-nav {
    width: calc(100% - 1rem);
    bottom: 0.5rem;
  }
  
  .nav-item {
    min-width: 56px;
  }
  
  .nav-item-icon {
    width: 20px;
    height: 20px;
  }
}
.nav-item:active .nav-item-icon {
  color: var(--primary-dark);
}

/* Animations des icônes */
@keyframes popAndBounce {
  0% { transform: scale(1); }
  30% { transform: scale(0.75); }
  60% { transform: scale(1.2); }
  80% { transform: scale(0.95); }
  100% { transform: scale(1); }
}

/* Application des animations */
.nav-item[data-view-mode] .nav-item-icon {
  animation: none;
}

/* Retrait des anciennes transitions au hover qui ne sont plus nécessaires */
.nav-item:hover .nav-item-icon {
  transform: none;
}

/* Ajuster la position du badge pendant l'animation */
.nav-item[data-animating="true"] .nav-item-badge {
  top: -4px;
  right: -8px;
}

/* Animation de splash pour le badge */
@keyframes badgeSplash {
  0% {
    transform: translate(50%, -50%) scale(1);
    opacity: 1;
  }
  15% {
    transform: translate(50%, -50%) scale(0.95);
    opacity: 1;
  }
  35% {
    transform: translate(50%, -50%) scale(1.15);
    opacity: 0.9;
  }
  100% {
    transform: translate(50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes splashParticle {
  0% {
    transform: translate(var(--particle-start-x, 0), var(--particle-start-y, 0)) scale(0.8);
    opacity: 1;
  }
  100% {
    transform: translate(var(--particle-end-x, 10px), var(--particle-end-y, 10px)) scale(0);
    opacity: 0;
  }
}

.nav-item-badge {
  position: absolute;
  top: -4px;
  right: -8px;
  background: var(--secondary-color);
  color: var(--text-on-secondary);
  font-size: 0.75rem;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  transform: translate(50%, -50%);
  border: 2px solid var(--color-beige-300);
  z-index: 2;
}

/* Effet de splash quand le nombre change */
.nav-item-badge[data-changing="true"] {
  animation: badgeSplash 0.5s var(--transition-timing-bounce);
}

.nav-item-badge[data-changing="true"]::before,
.nav-item-badge[data-changing="true"]::after,
.nav-item-badge[data-changing="true"]::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--secondary-color);
  border-radius: var(--radius-full);
  opacity: 0;
}

/* Particule droite */
.nav-item-badge[data-changing="true"]::before {
  top: 0;
  right: 0;
  --particle-start-x: -4px;
  --particle-start-y: 4px;
  --particle-end-x: 16px;
  --particle-end-y: -12px;
  animation: splashParticle 0.6s ease-out;
}

/* Particule gauche */
.nav-item-badge[data-changing="true"]::after {
  top: 0;
  left: 0;
  --particle-start-x: 4px;
  --particle-start-y: 4px;
  --particle-end-x: -16px;
  --particle-end-y: -12px;
  animation: splashParticle 0.5s ease-out 0.1s;
}

/* Particule centrale */
.nav-item-badge[data-changing="true"] .center-particle {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--secondary-color);
  border-radius: var(--radius-full);
  opacity: 0;
  top: 0;
  left: calc(50% - 2px);
  --particle-start-x: 0;
  --particle-start-y: 0;
  --particle-end-x: 0;
  --particle-end-y: -12px;
  animation: splashParticle 0.55s ease-out 0.05s;
}

.nav-item[aria-pressed="true"] {
  color: var(--primary-color);
}

.nav-item[aria-pressed="true"] .nav-item-icon {
  color: var(--primary-color);
}

.nav-item[aria-pressed="true"] .nav-item-label {
  color: var(--primary-color);
}

