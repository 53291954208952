/* Block: Le composant principal */
.event-card {
  position: relative;
  /* Supprimer les propriétés de swipe car elles sont gérées par SwipeableCard */
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* Modifier: Carte sans image */
.event-card--no-image {
  background: var(--background-secondary);
}

/* Element: Header de la carte */
.event-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Element: Contenu de la carte */
.event-card__content {
  padding: var(--spacing-md);
}


/* Element: Description de l'événement */
.event-card__description {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

/* Element: Détails de l'événement */
.event-card__details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

/* Element: Détail individuel */
.event-card__detail {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

/* Styles spécifiques aux événements récurrents */

/* Pattern de récurrence */
.event-card__time-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.event-card__time-info .recurrence-pattern {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  font-size: var(--font-size-md);
}

.event-card__time-info .hours {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

/* Liste des dates */
.event-card__dates.interactive {
  width: 100%;
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
}

.event-card__dates-toggle {
  width: 100%;
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-sans);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  transition: color var(--transition-fast);
  padding: var(--spacing-xs) 0;
}

.event-card__dates-toggle:hover {
  color: var(--primary-dark);
}

.event-card__dates-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-normal);
  touch-action: pan-y;
}

.event-card__dates-list--expanded {
  max-height: 200px;
  border-top: 1px solid var(--border-color-light);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-secondary);
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
}

.event-card__dates-list--expanded::-webkit-scrollbar {
  width: 4px;
}

.event-card__dates-list--expanded::-webkit-scrollbar-track {
  background: var(--background-secondary);
  border-radius: var(--radius-full);
}

.event-card__dates-list--expanded::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: var(--radius-full);
}

.event-card__date-item {
  padding: var(--spacing-sm);
  border-bottom: 1px solid var(--border-color-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-card__date-item:last-child {
  border-bottom: none;
}

.event-card__date-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.event-card__date {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
}

.event-card__time {
  color: var(--text-secondary);
  font-size: var(--font-size-xs);
}

.event-card__status--upcoming {
  background: var(--primary-light);
  color: var(--neutral-light);
  border-radius: var(--radius-full);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-xs);
}

.event-card__status--modified {
  background: var(--secondary-light);
  color: var(--neutral-light);
  border-radius: var(--radius-full);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-xs);
}



/* Media Queries */
@media (min-width: 768px) {
  .event-card__date-item {
    padding: var(--spacing-sm) var(--spacing-md);
  }
}

/* Optimisations tactiles */
@media (hover: none) {
  .event-card__dates-toggle:hover {
    background: var(--surface-light);
  }
}
