/* Base tag styles */
.tag {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius-full);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  font-family: inherit;
  line-height: 1.4;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.tag .tag-icon {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
}

/* Tag button styles */
.tag-button,
.ds-taglist__button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
  transition: opacity var(--transition-fast), transform var(--transition-fast);
}

.tag-button.selected .tag,
.ds-taglist__button--selected .tag {
  background: var(--tertiary-color);
  color: var(--text-on-primary);
}

/* État désactivé */
.ds-taglist__button--disabled {
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.ds-taglist__button--disabled .tag {
  background: var(--background-secondary);
  color: var(--text-tertiary);
}

.ds-taglist__button--disabled:hover {
  transform: none;
}

/* Focus states */
.tag-button:focus-visible,
.ds-taglist__button:focus-visible {
  outline: none;
}

.tag-button:focus-visible .tag,
.ds-taglist__button:focus-visible .tag {
  box-shadow: var(--focus-ring);
}

/* Hover effects */
.tag-button:not(:disabled):hover,
.ds-taglist__button:not(:disabled):hover {
  transform: translateY(-1px);
}

.tag-button:not(:disabled):active,
.ds-taglist__button:not(:disabled):active {
  transform: translateY(0);
}

/* Tag sizes */
.tag-sm {
  padding: 0.15rem 0.5rem;
  font-size: var(--font-size-xs);
}

.tag-lg {
  padding: 0.35rem 1rem;
  font-size: var(--font-size-md);
}

/* Tag colors by type */
.tag-activity {
  background: var(--background-color);
  color: var(--primary-color);
}

.tag-price {
  background: var(--primary-color);
  color: var(--background-color);
}

.tag-price.tag-free {
  background: var(--secondary-color);
  color: var(--text-on-primary);
  font-weight: var(--font-weight-semibold);
}

/* Effet halo pour les tags de prix sur les cartes avec image */
.tag-preview[data-preview-type="event"] > .tag-price,
.tag-preview[data-preview-type="event"] > .tag-price.tag-free {
  position: relative;
  z-index: 20;
}

.tag-preview[data-preview-type="event"] > .tag-price::before,
.tag-preview[data-preview-type="event"] > .tag-price.tag-free::before {
  content: '';
  position: absolute;
  inset: -4px;
  background: inherit;
  border-radius: inherit;
  filter: blur(8px);
  opacity: 0.7;
  z-index: -1;
}

.tag-status {
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.tag-status.tag-published {
  background: var(--primary-light);
  color: var(--text-on-primary);
}

.tag-status.tag-modified {
  background: var(--secondary-light);
  color: var(--text-on-primary);
}

.tag-status.tag-draft {
  background: var(--neutral-color);
  color: var(--text-secondary);
}

/* Layout containers */
.tag-group,
.tags-group,
.ds-taglist {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  align-items: center;
  margin: var(--spacing-md) 0;
}

/* Specific contexts */
/* 1. Carte avec image */
.tag-preview[data-preview-type="event"] {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 60px;
}

/* Positionnement commun pour les tags de prix et gratuit */
.tag-preview[data-preview-type="event"] > .tag-price,
.tag-preview[data-preview-type="event"] > .tag-price.tag-free {
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
  z-index: 20;
}

.tag-preview[data-preview-type="event"] .activity-tags {
  position: absolute;
  bottom: calc(var(--spacing-lg) + var(--spacing-md));
  left: var(--spacing-md);
  display: flex;
  gap: var(--spacing-xs);
  z-index: 3;
}

/* 2. Carte sans image */
.no-image-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 60px;
}

/* Wrapper pour les tags d'activité */
.activity-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  flex: 1;
  margin-left: 0;
}

/* Annuler le positionnement absolu pour les tags dans no-image-tags */
.no-image-tags .tag {
  position: static;
}

/* Responsive */
@media (max-width: 768px) {
  /* Supprimer le style générique qui écrase tout */
  /*.tag {
    font-size: var(--font-size-xs);
    padding: 0.2rem 0.6rem;
  }*/

  /* Taille par défaut */
  .tag:not(.tag-sm):not(.tag-lg) {
    font-size: var(--font-size-xs);
    padding: 0.2rem 0.6rem;
  }

  /* Petite taille */
  .tag-sm {
    padding: 0.1rem 0.4rem;
    font-size: calc(var(--font-size-xs) * 0.85);
  }

  /* Grande taille */
  .tag-lg {
    padding: 0.3rem 0.8rem;
    font-size: var(--font-size-sm);
  }
} 

/* Structure spécifique pour les cartes sans image */
.no-image-tag-container {
 padding-top: var(--spacing-sm);
 padding-bottom: var(--spacing-sm);
}

.no-image-tag-container .tags-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  width: 100%;
}

.no-image-tag-container .tag {
  flex-shrink: 0;
}

/* Responsive */


.price-tag-wrapper {
  flex-shrink: 0;
}

.activity-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  flex: 1;
  margin-left: 0;
}

/* Annuler les anciens styles qui pourraient entrer en conflit */
.no-image-tag-container .tag {
  position: static;
}