/* Typography Showcase Styles */
.typography-families {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.typography-family {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.font-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Font Sizes */
.typography-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.typography-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.typography-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Styles spécifiques pour chaque taille de police */
[data-font-size="xs"] .typography-preview { font-size: var(--font-size-xs); }
[data-font-size="sm"] .typography-preview { font-size: var(--font-size-sm); }
[data-font-size="md"] .typography-preview { font-size: var(--font-size-md); }
[data-font-size="lg"] .typography-preview { font-size: var(--font-size-lg); }
[data-font-size="xl"] .typography-preview { font-size: var(--font-size-xl); }
[data-font-size="2xl"] .typography-preview { font-size: var(--font-size-2xl); }
[data-font-size="3xl"] .typography-preview { font-size: var(--font-size-3xl); }

.typography-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.typography-name {
  font-family: var(--font-family-mono);
  color: var(--text-primary);
}

.typography-value {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-mono);
}

.typography-usage {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

/* Font Weights */
.typography-weights {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.weight-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.weight-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
}

/* Styles spécifiques pour chaque poids de police */
[data-font-weight="regular"] .weight-preview { font-weight: var(--font-weight-regular); }
[data-font-weight="medium"] .weight-preview { font-weight: var(--font-weight-medium); }
[data-font-weight="semibold"] .weight-preview { font-weight: var(--font-weight-semibold); }
[data-font-weight="bold"] .weight-preview { font-weight: var(--font-weight-bold); }

/* Line Heights */
.line-height-examples {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.line-height-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.line-height-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
  font-size: var(--font-size-md);
}

/* Styles spécifiques pour chaque hauteur de ligne */
[data-line-height="tight"] .line-height-preview { line-height: var(--line-height-tight); }
[data-line-height="normal"] .line-height-preview { line-height: var(--line-height-normal); }
[data-line-height="relaxed"] .line-height-preview { line-height: var(--line-height-relaxed); }

/* Letter Spacing */
.letter-spacing-examples {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.letter-spacing-item {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.letter-spacing-preview {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

/* Styles spécifiques pour chaque espacement de lettres */
[data-letter-spacing="tight"] .letter-spacing-preview { letter-spacing: var(--letter-spacing-tight); }
[data-letter-spacing="normal"] .letter-spacing-preview { letter-spacing: var(--letter-spacing-normal); }
[data-letter-spacing="wide"] .letter-spacing-preview { letter-spacing: var(--letter-spacing-wide); }

/* Typography Examples */
.typography-examples {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.typography-example {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.typography-label {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .typography-grid {
    grid-template-columns: 1fr;
  }
}