.search-area-button-container {
  position: fixed;
  bottom: calc(var(--bottom-nav-height) + var(--spacing-xl)); /* Position au-dessus de la bottom nav */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
}

.search-area-button {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-full);
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-fast) var(--transition-timing-bounce);
}

.search-area-button:active {
  transform: scale(var(--scale-active));
}

.search-area-button .icon {
  color: var(--primary-color);
}

.search-area-button-container .btn {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translate(-50%, calc(var(--spacing-lg) * -1));
  }
  to { 
    opacity: 1;
    transform: translate(-50%, 0);
  }
} 