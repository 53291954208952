.section-content {
  padding: var(--spacing-lg);
}

.section-description {
  margin-bottom: var(--spacing-lg);
  color: var(--text-secondary);
}

.subsection {
  margin-bottom: var(--spacing-xl);
}

.subsection h4 {
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
}

/* Zone de démonstration */
.showcase-interactive {
  margin: var(--spacing-lg) 0;
}

.demo-container {
  position: relative;
  width: 100%;
  max-width: 750px;
  height: 667px;
  margin: 0 auto;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Isolation des composants fixed */
.demo-container .bottom-nav {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  width: auto;
  transform: none;
}

.demo-container .filter-drawer {
  position: absolute;
  max-height: calc(100% - 64px);
}

.demo-container .filter-drawer-overlay {
  position: absolute;
}

/* Contenu simulé */
.demo-content {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
}

.demo-placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
  color: var(--text-tertiary);
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
}

.demo-placeholder p {
  margin: 0;
  font-size: var(--font-size-sm);
}

/* Zone des filtres actifs */
.active-filters {
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  min-height: 48px;
  background: var(--background-color);
  border-top: 1px solid var(--border-color);
}

/* Anatomie */
.anatomy-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-md);
}

.anatomy-item {
  padding: var(--spacing-md);
  background: var(--background-secondary);
  border-radius: var(--radius-md);
}

.anatomy-item h5 {
  margin: 0 0 var(--spacing-sm);
  color: var(--text-primary);
}

.anatomy-item p {
  margin: 0 0 var(--spacing-sm);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.anatomy-item code {
  display: block;
  padding: var(--spacing-sm);
  background: var(--background-tertiary);
  border-radius: var(--radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-code);
}

/* Code example */
.component-code {
  margin-top: var(--spacing-md);
}

.code-example {
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.code-example h3 {
  margin: 0;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-md);
  color: var(--text-primary);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.code-example h3::after {
  content: '▼';
  margin-left: auto;
  transform: rotate(0deg);
  transition: transform 0.3s var(--transition-timing-smooth);
}

.code-example.collapsed h3::after {
  transform: rotate(-90deg);
}

.code-example pre {
  margin: 0;
  padding: var(--spacing-md);
  overflow-x: auto;
  transition: max-height 0.3s var(--transition-timing-smooth);
}

.code-example.collapsed pre {
  max-height: 0;
  padding: 0 var(--spacing-md);
  overflow: hidden;
}

.code-example code {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-code);
  line-height: 1.5;
}

/* Animation pour les chips */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.active-filters > * {
  animation: slideIn 0.2s var(--transition-timing-bounce) forwards;
}