/* Styles de base du composant swipeable */
.swipeable-card {
  position: relative;
  transform-origin: center center;
  will-change: transform;
  cursor: grab;
  touch-action: pan-y pinch-zoom;
  user-select: none;
  -webkit-user-select: none;
  width: 100%; /* Ajout */
  margin: var(--spacing-xs) auto; /* Ajout */
  padding: 0 var(--spacing-xs); /* Ajout */
  box-sizing: border-box; 
}

.swipeable-card:active {
  cursor: grabbing;
}

/* Indicateurs de swipe */
.swipeable-card__indicators {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
}

.swipeable-card__indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.swipeable-card__indicator--like {
  right: 50px;
  color: var(--primary-color);
}

.swipeable-card__indicator--dismiss {
  left: 50px;
  color: var(--secondary-color);
}

/* Optimisations de performance */
.swipeable-card {
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

/* Gestion des interactions enfants */
.swipeable-card * {
  pointer-events: none;
}

/* Permettre les interactions sur les éléments spécifiques */
.swipeable-card button,
.swipeable-card a,
.swipeable-card [role="button"],
.swipeable-card input,
.swipeable-card .interactive {
  pointer-events: auto;
  position: relative;
  z-index: 2;
}

/* Responsive */
@media (hover: none) {
  .swipeable-card {
    touch-action: pan-y pinch-zoom;
  }
} 