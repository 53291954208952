.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-color);
}

.loading-screen__content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 320px;  /* Même largeur que index.html */
  margin: 0 auto;
}

.loading-screen__logo {
  position: absolute;
  top: 25%;  /* Même position que index.html */
  left: 50%;
  transform: translateX(-50%);
  width: 220px;  /* Augmenté de 180px à 220px */
}

.loading-screen__joke {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color, #333);
  width: 280px;
  text-align: center;
  line-height: 1.4;
}

.loading-screen__title {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  
  font-size: 0.9rem;
  color: var(--text-secondary, #666);
  width: 100%;
  text-align: center;
  margin: 0;
}

.loading-screen__monkey {
  font-size: 4rem;
  margin: 1rem 0;
  animation: bounce 1s infinite;
}

.loading-screen__spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-primary-light);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  margin: 2rem auto 0;
  animation: spin 1s linear infinite;
}

.loading-screen__location {
  max-width: 400px;
}

.loading-screen__error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--error-color);
  text-align: center;
  width: 280px;  /* Même largeur que les autres éléments */
  font-size: 0.9rem;
}

@keyframes pulse {
  0% { transform: translateX(-50%) scale(1); }
  50% { transform: translateX(-50%) scale(1.05); }
  100% { transform: translateX(-50%) scale(1); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Style pour le LocationFilter quand il est dans LoadingScreen */
.loading-screen .location-filter {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;  /* Même largeur que la joke */
}

.loading-screen .location-filter__title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color, #333);
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.4;
}
