/* Import des variables et styles de base */
@import './variables.css';
@import './form-components.css';

/* Styles globaux */
html, body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-primary);
  min-height: 100vh;
}

body {
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

/* Styles pour tous les titres */
h1, h2, h3, h4, h5, h6,
.title, .heading {
  font-family: var(--font-family-headings);
  font-weight: 700; /* Moonlight par défaut */
  letter-spacing: -0.02em;
  line-height: var(--line-height-tight);
}

/* Style spécifique pour h1 et h2 en Bold si tu le souhaites */
h1, h2 {
  font-weight: 700; /* Bold pour les titres principaux */
}

/* Ajustement de l'interlignage pour h3 et*/
h3 {
  line-height: 1.4; /* Légèrement plus espacé que --line-height-tight qui est à 1.2 */
}

* {
  box-sizing: border-box;
} 

