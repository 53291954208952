.organizer-profile {
  width: 100%;
  max-width: var(--content-width-lg);
  margin: 0 auto;
  padding: var(--spacing-lg);
}

@media (max-width: 768px) {
  .organizer-profile {
    padding: var(--spacing-md);
  }
} 