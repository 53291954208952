.animated-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  height: 100%; 
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: auto;
  scroll-behavior: smooth;
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
  perspective: 1000px; /* Aide à la fluidité des animations 3D */
  padding-top: var(--spacing-sm);
}

.animated-list > div {
  width: 100%;
  will-change: transform;
  pointer-events: auto;
  transform-origin: center center;
  /* Ajout pour une meilleure performance */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.list-bottom-spacer {
  height: calc(
    var(--spacing-sm) + /* padding de base de la nav */
    var(--spacing-xl) + /* espace supplémentaire */
    env(safe-area-inset-bottom, 1rem) + /* safe area iOS */
    64px /* hauteur de base de la nav */
  );
  flex-shrink: 0;
  pointer-events: none;
} 