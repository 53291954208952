@import '../../../styles/design-tokens.css';

/* Marqueur de position utilisateur */
.user-location-marker {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary-color);
  opacity: 0.9;
  box-shadow: 0 0 0 1.5px var(--background-color);
}

.user-location-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  background: var(--background-color);
  border-radius: 50%;
}

/* Animation de pulsation */
.user-location-marker::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  background: var(--primary-light);
  opacity: 0.04;
  animation: pulse 2s ease-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.04;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} 