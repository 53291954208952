/* Import des polices personnalisées */
@font-face {
  font-family: 'Basteleur';
  src: url('./fonts/Basteleur-Bold.woff2') format('woff2'),
       url('./fonts/Basteleur-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basteleur';
  src: url('./fonts/Basteleur-Moonlight.woff2') format('woff2'),
       url('./fonts/Basteleur-Moonlight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  /* Désactiver l'effet de tap highlight sur mobile globalement */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  /* ===== COULEURS SÉMANTIQUES ===== */
  /* Couleurs de marque */
  --primary-color: var(--color-green-300);
  --primary-light: var(--color-green-200);
  --primary-dark: var(--color-green-400);

  --secondary-color: var(--color-red-300);
  --secondary-light: var(--color-red-200);
  --secondary-dark: var(--color-red-400);

  --tertiary-color: var(--color-blue-300);
  --tertiary-light: var(--color-blue-200);
  --tertiary-dark: var(--color-blue-400);

  --neutral-color: var(--color-beige-200);
  --neutral-light: var(--color-beige-100);
  --neutral-dark: var(--color-beige-300);

  /* Couleurs de texte */
  --text-primary: var(--color-gray-500);
  --text-secondary: var(--color-gray-400);
  --text-tertiary: var(--color-gray-300);
  --text-on-primary: var(--color-gray-100);
  --text-on-secondary: var(--color-gray-100);
  --text-disabled: var(--color-gray-300);

  /* Couleurs de fond */
  --background-color: var(--color-background-primary);
  --background-secondary: var(--color-background-secondary);
  --background-tertiary: var(--color-background-tertiary);

  /* Couleurs de bordure */
  --border-color: var(--color-border);
  --border-color-dark: color-mix(in srgb, var(--color-border) 85%, black);
  --border-color-light: color-mix(in srgb, var(--color-border) 85%, white);

  /* ===== ESPACEMENTS SÉMANTIQUES ===== */
  --spacing-xs: var(--space-1);
  --spacing-sm: var(--space-2);
  --spacing-md: var(--space-4);
  --spacing-lg: var(--space-5);
  --spacing-xl: var(--space-6);

  /* ===== ESPACEMENTS COMPONENTS ===== */
  --space-xs: var(--space-1);
  --space-sm: var(--space-2);
  --space-md: var(--space-3);
  --space-lg: var(--space-4);
  --space-xl: var(--space-5);
  --space-xxl: var(--space-6);


  /* ===== RADIUS (ARRONDIS) ===== */
  --radius-sm: 0.25rem;    /* 4px */
  --radius-md: 0.5rem;     /* 8px */
  --radius-lg: 0.75rem;    /* 12px */
  --radius-xl: 1rem;       /* 16px */
  --radius-full: 9999px;   /* Cercle parfait */

  /* ===== TYPOGRAPHIE ===== */
  --font-size-xs: 0.75rem;    /* 12px */
  --font-size-sm: 0.875rem;   /* 14px */
  --font-size-md: 1rem;       /* 16px */
  --font-size-lg: 1.125rem;   /* 18px */
  --font-size-xl: 1.25rem;    /* 20px */
  --font-size-2xl: 1.5rem;    /* 24px */
  --font-size-3xl: 1.875rem;  /* 30px */

  /* Poids de police */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Hauteurs de ligne */
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;

  /* Familles de polices */
  --font-family-sans: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-family-headings: 'Basteleur', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  /* Espacement des lettres */
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0;
  --letter-spacing-wide: 0.025em;

  /* ===== TRANSITIONS ===== */
  --transition-timing-bounce: cubic-bezier(0.17, 0.93, 0.38, 1);
  --transition-timing-smooth: cubic-bezier(0.4, 0, 0.2, 1);
  --transition-timing-spring: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  --transition-fast: var(--duration-200);
  --transition-normal: var(--duration-300);
  --transition-slow: var(--duration-500);

  /* Transitions spéciales */
  --transition-bounce: var(--duration-300) var(--transition-timing-bounce);
  --transition-spring: var(--duration-300) var(--transition-timing-spring);

  /* ===== COMPOSANTS ===== */
  /* Boutons */
  --button-height-sm: var(--size-4);
  --button-height-md: calc(var(--size-4) * 1.25);
  --button-height-lg: calc(var(--size-4) * 1.5);
  --button-padding-x: var(--spacing-md);
  --button-padding-y: var(--spacing-sm);
  --button-font-size: var(--font-size-sm);
  --button-border-radius: var(--radius-md);

  /* Cartes */
  --card-padding: var(--spacing-lg);
  --card-border-radius: var(--radius-lg);
  --card-background: var(--background-color);

  /* Inputs */
  --input-height: var(--size-8);
  --input-padding-x: var(--spacing-md);
  --input-padding-y: var(--spacing-sm);
  --input-border-radius: var(--radius-md);
  --input-background: var(--background-color);
  --input-font-size: var(--font-size-sm);
  --input-line-height: var(--line-height-normal);

  /* ===== ÉTATS & INTERACTIONS ===== */
  --opacity-disabled: var(--opacity-50);
  --opacity-hover: var(--opacity-75);
  --scale-hover: 1.02;
  --scale-active: 0.98;
} 