/* ===== BINARY TOGGLE ===== */
.binary-toggle-container {
  position: relative;
  display: inline-flex;
  background-color: var(--background-tertiary);
  padding: 4px;
  border-radius: var(--radius-full);
  width: fit-content;
  min-width: 200px;
  max-width: 400px;
  height: 44px;
  border: 1px solid var(--border-color);
}

.binary-toggle-option {
  flex: 1;
  position: relative;
  z-index: 2;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-xl);
}

.binary-toggle-option input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

.binary-toggle-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: var(--transition-spring);
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Le slider qui se déplace */
.binary-toggle-container::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(50% - 3px);
  height: calc(100% - 6px);
  background-color: var(--primary-color);
  border-radius: var(--radius-full);
  transition: transform var(--transition-spring); 
  z-index: 1;
}

/* Position du slider */
.binary-toggle-container:has(.binary-toggle-option:last-child input[type="radio"]:checked)::after {
  transform: translateX(calc(100% - 3px));
}

/* Couleur du texte sur le slider */
.binary-toggle-container:not(:has(.binary-toggle-option:last-child input[type="radio"]:checked)) .binary-toggle-option:first-child .binary-toggle-label,
.binary-toggle-container:has(.binary-toggle-option:last-child input[type="radio"]:checked) .binary-toggle-option:last-child .binary-toggle-label {
  color: var(--text-on-primary);
}

/* État hover */
.binary-toggle-option:hover .binary-toggle-label {
  color: var(--text-primary);
}

/* État focus */
.binary-toggle-option input[type="radio"]:focus-visible ~ .binary-toggle-label {
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
  border-radius: var(--radius-full);
}

/* État disabled */
.binary-toggle-container.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.binary-toggle-container.disabled .binary-toggle-label {
  cursor: not-allowed;
}