.modal-header {
  position: relative;
  width: 100%;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  overflow: hidden;
  background: var(--background-secondary);
}

.modal-header-image {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Ratio 16:9 */
  overflow: hidden;
  z-index: 1; /* Crée un nouveau stacking context avec un z-index bas */
}

.modal-header-clickable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%; /* Réduit la zone cliquable à la partie supérieure */
  cursor: pointer;
}

.modal-header-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-header-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65%;
  padding: var(--spacing-4);
  background: linear-gradient(
    to top,
    var(--background-secondary) 0%,
    color-mix(in srgb, var(--background-secondary) 95%, transparent) 25%,
    color-mix(in srgb, var(--background-secondary) 50%, transparent) 50%,
    transparent 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none; /* Permet de cliquer à travers l'overlay */
}

.modal-header-tags {
  pointer-events: auto; /* Réactive les événements pour les tags */
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  padding: var(--spacing-md);
  margin-bottom: 0; /* Suppression de la marge en bas */
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-header-tags .tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.modal-header-expand {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border-radius: var(--radius-full);
  padding: var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0.8;
  transition: opacity var(--transition-fast);
}

.modal-header-expand:hover {
  opacity: 1;
}

/* Version sans image */
.modal-header.no-image {
  position: relative;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: var(--background-secondary);
}

.modal-header.no-image .modal-header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.modal-header.no-image .modal-header-tags {
  padding: 0;
  margin: 0;
  width: 100%;
}

/* Optimisations tactiles */
@media (hover: none) {
  .modal-header {
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.modal-header-maximize {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  color: white;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  padding: var(--spacing-2);
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity var(--transition-fast);
  z-index: 2;
  border: none;
  cursor: pointer;
}

.modal-header-maximize:hover {
  opacity: 1;
} 