.controls-showcase {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.control-type-container {
  padding: var(--spacing-lg);
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
}

.control-group {
  margin-bottom: var(--spacing-lg);
}

.control-group h4 {
  margin: 0 0 var(--spacing-xs);
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.control-description {
  margin: 0 0 var(--spacing-md);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.control-preview {
  padding: var(--spacing-lg);
  background: var(--background-primary);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
}

.component-code {
  margin-top: var(--spacing-md);
}

.code-example {
  background: var(--background-tertiary);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.code-example h3 {
  margin: 0;
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--background-quaternary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
}

.code-example pre {
  margin: 0;
  padding: var(--spacing-md);
  color: var(--text-primary);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  white-space: pre-wrap;
  overflow-x: auto;
}

.code-example.collapsed pre {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .control-preview {
    padding: var(--spacing-md);
  }

  .control-type-container {
    padding: var(--spacing-md);
  }
}

.locale-test-section {
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-md);
  background: var(--background-tertiary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
}

.locale-test-section p {
  margin-bottom: var(--spacing-sm);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.locale-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.locale-buttons button {
  min-width: auto;
  font-size: var(--font-size-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
}

/* Responsive */
@media (max-width: 768px) {
  .locale-buttons {
    gap: var(--spacing-xxs);
  }
  
  .locale-buttons button {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }
} 