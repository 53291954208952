/* ===== MULTI SELECT TOGGLE ===== */
.multi-select-group {
  display: inline-flex;
  background-color: var(--background-tertiary);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xs);
  gap: var(--spacing-xs);
  font-family: var(--font-family-sans);
  width: 100%;
}

.multi-select-button {
  min-width: 20px;
  width: 100%;
  height: 44px;
  padding: 0;
  border: none;
  background: var(--background-color);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-sans);
  cursor: pointer;
  border-radius: var(--radius-md);
  transition: all var(--transition-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.multi-select-button:hover {
  color: var(--primary-color);
  background-color: var(--neutral-light);
}

.multi-select-button.active {
  background-color: var(--primary-color);
  color: white;
}

.multi-select-button.active:hover {
  background-color: var(--primary-light);
  color: white;  /* Garde le texte blanc même au hover quand actif */
}

.multi-select-button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
}

.multi-select-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: var(--background-tertiary);
}

.multi-select-toggle {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  font-family: var(--font-family-sans);
}

.multi-select-toggle__option {
  font-family: var(--font-family-sans);
}

.multi-select-toggle__label {
  font-family: var(--font-family-sans);
}