.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: fit-content;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.checkbox-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.checkbox-box {
  position: relative;
  width: 18px;
  height: 18px;
  border: 2px solid var(--primary-light);
  border-radius: var(--radius-sm);
  background: var(--background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-normal);
}

.checkbox-input:checked + .checkbox-box {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox-box.is-indeterminate {
  background: var(--background-tertiary);
  border-color: var(--border-color);
}

.checkbox-input:focus-visible + .checkbox-box {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Hover pour checkbox non cochée */
.checkbox-container:hover:not(.is-disabled) .checkbox-box:not(.is-indeterminate) {
  border-color: var(--primary-color);
  background-color: color-mix(in srgb, var(--background-color) 95%, var(--primary-color));
  transform: scale(1.05);
}

/* Hover pour checkbox cochée */
.checkbox-container:hover:not(.is-disabled) .checkbox-input:checked + .checkbox-box {
  background: color-mix(in srgb, var(--primary-color) 85%, white);
  border-color: var(--primary-color);
  transform: scale(1.05);
}

.checkbox-icon {
  color: var(--neutral-light);
  stroke-width: 3;
  transform: scale(1.2);
}

.checkbox-box.is-indeterminate .checkbox-icon {
  color: var(--text-primary);
  stroke-width: 3;
  transform: scale(1.4);
}

.checkbox-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  user-select: none;
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.checkbox-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.checkbox-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
}

.checkbox-container.has-error .checkbox-box {
  border-color: var(--secondary-color);
}

.checkbox-container.is-disabled {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}

.checkbox-container.is-disabled .checkbox-box {
  background: var(--background-tertiary);
  border-color: var(--border-color);
}

.checkbox-container.is-disabled .checkbox-icon {
  display: none;
}
 