.stats-container {
    padding: 20px;
  }
  
  .stats-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .stat-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .stat-info {
    display: flex;
    flex-direction: column;
  }
  
  .stat-label {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-top: 0.25rem;
  }
  
  .stat-icon-container {
    padding: 0.75rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .stat-icon-container .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  /* Variantes de couleur */
  .stat-icon-container.green {
    background-color: #f0fdf4;
  }
  
  .stat-icon-container.green .icon {
    color: #16a34a;
  }
  
  .stat-icon-container.blue {
    background-color: #eff6ff;
  }
  
  .stat-icon-container.blue .icon {
    color: #2563eb;
  }
  
  .stat-icon-container.purple {
    background-color: #faf5ff;
  }
  
  .stat-icon-container.purple .icon {
    color: #9333ea;
  }
  
  /* Styles existants pour la carte d'événement populaire */
  .popular-event-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
  }
  
  .popular-event-card h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .popular-event-content {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .popular-event-content img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .popular-event-info h4 {
    margin: 0 0 5px 0;
    color: #333;
  }
  
  .popular-event-info p {
    color: #666;
  }