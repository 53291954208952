/* Block: organizer-list */
.organizer-list {
  width: 100%;
  max-width: var(--size-content-max);
  margin: 0 auto;
  
}

.organizer-list__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

/* Block: organizer-card */
.organizer-card {
  display: flex;
  flex-direction: column;
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--border-color-light);
}

.organizer-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* Card modifiers */
.organizer-card--recurring {
  padding: 0;
}

.organizer-card--empty {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xl);
}

/* Card elements */
.organizer-card__header {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  padding: var(--spacing-md);
  
}

.organizer-card__header--sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.organizer-card__header--with-border {
  border-bottom: 1px solid var(--border-color-light);
}

.organizer-card__image {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: var(--radius-md);
  overflow: hidden;
  background-color: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.organizer-card__image-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.organizer-card__logo {
  width: 100%;
  height: 100%;
  padding: var(--spacing-md);
}

.organizer-card__content {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.organizer-card__title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organizer-card__date {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
}

.organizer-card__location {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
}

.organizer-card__recurrence-pattern {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
  font-style: italic;
}

.organizer-card__actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  min-width: 100px;
}

/* Block: organizer-occurrences */
.organizer-occurrences {
  max-height: calc(7 * (var(--spacing-md) * 2 + 24px));
  overflow-y: auto;
  padding-right: var(--spacing-sm);
}

.organizer-occurrences__title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: var(--spacing-md) 0 var(--spacing-xs);
}

.organizer-occurrences__content {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transform-origin: top;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.3s var(--transition-timing-spring);
  will-change: transform, max-height, opacity;
}

.organizer-occurrences__content--expanded {
  max-height: 800px;
  transform: translateY(0);
  opacity: 1;
  padding: var(--spacing-md);
}

/* Block: organizer-occurrence */
.organizer-occurrence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background: white;
  border-bottom: 1px solid var(--border-color-light);
  animation: slideIn 0.3s var(--transition-timing-spring) backwards;
}

.organizer-occurrence:last-child {
  border-bottom: none;
}

.organizer-occurrence__info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.organizer-occurrence__date {
  display: flex;
  gap: var(--spacing-sm);
}

.organizer-occurrence__date-value {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.organizer-occurrence__time {
  color: var(--text-secondary);
}

.organizer-occurrence__modified {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.organizer-occurrence__actions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  align-items: center;
}

/* Scrollbar styles */
.organizer-occurrences::-webkit-scrollbar {
  width: 4px;
}

.organizer-occurrences::-webkit-scrollbar-track {
  background: var(--background-secondary);
  border-radius: var(--radius-full);
}

.organizer-occurrences::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: var(--radius-full);
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.organizer-occurrence:nth-child(1) { animation-delay: 0.1s; }
.organizer-occurrence:nth-child(2) { animation-delay: 0.15s; }
.organizer-occurrence:nth-child(3) { animation-delay: 0.2s; }
.organizer-occurrence:nth-child(4) { animation-delay: 0.25s; }
.organizer-occurrence:nth-child(5) { animation-delay: 0.3s; }
.organizer-occurrence:nth-child(6) { animation-delay: 0.35s; }
.organizer-occurrence:nth-child(7) { animation-delay: 0.4s; }

/* Mobile Responsive */
@media (max-width: 768px) {
  .organizer-card__header,
  .organizer-card__header--sticky {
    flex-direction: column;
  }

  .organizer-card__image {
    width: 100%;
    height: 160px;
  }

  .organizer-card__content {
    width: 100%;
    padding-right: 0;
  }

  .organizer-card__actions {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: var(--spacing-sm);
  }
}
