/* ==========================================================================
   Event Form Component Styles
   Styles spécifiques au formulaire d'événement, complémentaires au Design System
   ========================================================================== */

/* Structure principale
   ========================================================================== */
.event-form-overlay {
    position: fixed;
    inset: 0;
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: column;
    z-index: 9999;
    animation: fadeIn var(--transition-fast) ease-out;
}
  
.event-form-modal {
    background-color: var(--background-secondary);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding: var(--spacing-lg) var(--spacing-xl);
    max-width: 1200px;
    margin: 0 auto;
    animation: slideIn var(--transition-normal) ease-out;
}

.event-form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    max-width: 800px;
}

/* Header spécifique
   ========================================================================== */
.event-form__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
    padding-bottom: var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
}

.event-form__title {
    font-family: var(--font-family-headings);
    font-size: var(--font-size-xl);
    color: var(--text-primary);
    margin: 0;
}

.event-form__close {
    background: none;
    border: none;
    color: var(--text-secondary);
    padding: var(--spacing-xs);
    border-radius: var(--radius-full);
    transition: all var(--transition-fast);
    cursor: pointer;
}

.event-form__close:hover {
    background: var(--neutral-light);
    color: var(--text-primary);
}

/* Structure des étapes
   ========================================================================== */
.event-form__step {
    margin: var(--spacing-lg) 0;
}

.event-form__step-content {
    display: flex;
    flex-direction: column;
    
}

/* Labels et textes d'aide
   ========================================================================== */
.event-form__label {
    display: block;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xs);
}

.event-form__label--required::after {
    content: '*';
    color: var(--secondary-color);
    margin-left: var(--spacing-xs);
}

.event-form__label-hint {
    font-size: var(--font-size-xs);
    color: var(--text-tertiary);
    font-weight: var(--font-weight-regular);
    margin-left: var(--spacing-xs);
}

.event-form__error {
    color: var(--secondary-color);
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-xs);
}

/* Groupes de formulaire spécifiques
   ========================================================================== */
.event-form__group {
    margin-bottom: var(--spacing-md);
}

.event-form__group--row {
    display: flex;
    gap: var(--spacing-md);
}

/* Section temps spécifique
   ========================================================================== */
.event-form__date {
    flex: 1;
}

.event-form__time-range {
    flex: 1;
}

.event-form__time-inputs {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
}

.event-form__time-from,
.event-form__time-to {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.event-form__time-label {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
}

/* Section récurrence
   ========================================================================== */
.event-form__recurrence {
    margin-top: var(--spacing-md);
    padding: var(--spacing-md);
    background: var(--background-secondary);
    border-radius: var(--radius-md);
}

.event-form__recurrence-question,
.event-form__frequency-question {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
}

/* Actions
   ========================================================================== */
.event-form__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing-md);
    border-top: 1px solid var(--border-color-light);
    position: relative;
}

.event-form__button--previous {
    margin-right: auto;
}

.event-form__button--next {
    margin-left: auto;
}

.event-form__actions--final {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-lg);
}

/* Preview spécifique
   ========================================================================== */
.preview-step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.event-form__image-preview {
    margin-top: var(--spacing-md);
    width: 100%;
    max-width: 300px;
    aspect-ratio: 3/4;
    border-radius: var(--radius-md);
    overflow: hidden;
    border: 1px solid var(--border-color);
    background-color: var(--background-secondary);
}

.event-form__image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Animations
   ========================================================================== */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive
   ========================================================================== */
@media (max-width: 768px) {
    .event-form-modal {
        padding: var(--spacing-md);
    }

    .event-form__group--row {
        flex-direction: column;
        gap: var(--spacing-sm);
    }

    .event-form__actions {
        flex-direction: column-reverse;
        gap: var(--spacing-sm);
    }

    .event-form__actions--final {
        width: 100%;
        justify-content: space-between;
    }
}

/* État du body quand le modal est ouvert
   ========================================================================== */
body.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.required-field-indicator {
  color: var(--color-error);
  margin-left: 4px;
}

.event-form__field--error {
  border-color: var(--color-error);
  animation: shake 0.5s;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

.event-form__button--draft-inline {
  background-color: transparent;
  color: var(--color-text-secondary);
  border: 1px solid var(--color-border);
}

/* Styles corrigés pour les boutons */
.event-form__buttons-container {
  display: flex;
  width: 100%;
  align-items: center; /* Aligne verticalement les boutons */
  margin-top: 1.5rem;
}

.event-form__spacer {
  flex: 1; /* Prend tout l'espace disponible */
}

.event-form__right-buttons {
  display: flex;
  gap: 12px; /* Espace entre les boutons */
  align-items: center;
}

.event-form__button--back {
  margin-right: 12px;
}

.event-form__button--draft {
  background-color: transparent;
  color: var(--color-text-secondary);
  border: 1px solid var(--color-border);
}

/* Styles pour les messages d'erreur conviviaux */
.form-error-message {
  color: var(--color-error, #e53935);
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-5px); }
  to { opacity: 1; transform: translateY(0); }
}

/* S'assurer que les champs avec erreur ont une bordure rouge */
input[aria-invalid="true"],
textarea[aria-invalid="true"],
select[aria-invalid="true"] {
  border-color: var(--color-error, #e53935);
  box-shadow: 0 0 0 1px var(--color-error, #e53935);
}

/* Ajoutez ce CSS pour s'assurer que le conteneur d'autocomplétion est visible */
/* Ajoutez ce CSS */
.pac-container {
    z-index: 10000 !important; 
  }

/* Animation du spinner de chargement */
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Ajustement pour le bouton outline */
.event-form__button--draft .loading-spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
}