/* Foundation Indicators Showcase Styles */

/* Section layout */
.design-subsection {
  margin-bottom: var(--spacing-2xl);
}

/* Common showcase grid layout */
.showcase-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

/* Common showcase card */
.showcase-item {
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  padding: 1.5rem;
}

/* Common preview area */
.preview-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-sm);
}

/* Specific preview areas */
.tag-preview,
.icon-preview,
.marker-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-sm);
}

/* Information area */
.showcase-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.showcase-name {
  font-family: var(--font-family-mono);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.showcase-usage {
  font-size: var(--font-size-sm);
  color: var(--text-color-light);
}

/* Examples section */
.showcase-example {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.showcase-example h4 {
  margin-bottom: var(--spacing-sm);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .showcase-grid {
    grid-template-columns: 1fr;
  }
} 

.indicators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.indicator-card {
  background: var(--background-color);
  border-radius: var(--radius-lg);
  padding: 2rem;
  box-shadow: var(--shadow-sm);
  transition: box-shadow 0.3s ease;
}

.indicator-card:hover {
  box-shadow: var(--shadow-md);
}

.indicator-content {
  margin-top: 1.5rem;
}

.indicator-card h3 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.indicator-card p {
  color: var(--text-color-light);
  margin-bottom: 1.5rem;
}

.showcase-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.showcase-item {
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  padding: 1.5rem;
}

.tag-preview {
  margin-bottom: 1rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showcase-info code {
  display: block;
  background: var(--background-tertiary);
  padding: 0.5rem;
  border-radius: var(--radius-sm);
  margin-bottom: 0.5rem;
  font-family: var(--font-family-mono);
}

.showcase-usage {
  font-size: var(--font-size-sm);
  color: var(--text-color-light);
}

@media (max-width: 768px) {
  .indicators-grid {
    grid-template-columns: 1fr;
  }
  
  .showcase-grid {
    grid-template-columns: 1fr;
  }
} 

/* Styles de base */
.design-section {
  max-width: 1200px;
  margin: 0 auto;
}

/* Styles pour les sections dépliables */
.collapsible-section {
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  overflow: hidden;
}

.collapsible-header {
  width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.collapsible-header:hover {
  background: var(--background-secondary);
}

.collapsible-header h3 {
  margin: 0;
  color: var(--text-color);
  font-size: var(--font-size-lg);
}

.collapsible-icon {
  width: 24px;
  height: 24px;
  position: relative;
}

.collapsible-icon::before,
.collapsible-icon::after {
  content: '';
  position: absolute;
  background-color: var(--text-color);
  transition: transform 0.3s ease;
}

.collapsible-icon::before {
  width: 2px;
  height: 16px;
  top: 4px;
  left: 11px;
}

.collapsible-icon::after {
  width: 16px;
  height: 2px;
  top: 11px;
  left: 4px;
}

.collapsible-header.active .collapsible-icon::before {
  transform: rotate(90deg);
}

.collapsible-content {
  padding: 0 2rem 2rem;
  background: var(--background-color);
}

/* Grille d'indicateurs */
.indicators-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.indicator-card {
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: 2rem;
}

/* Styles pour les éléments de présentation */
.showcase-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.showcase-item {
  background: var(--background-tertiary);
  border-radius: var(--radius-md);
  padding: 1.5rem;
}

.tag-preview {
  margin-bottom: 1rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showcase-info code {
  display: block;
  background: var(--background-color);
  padding: 0.5rem;
  border-radius: var(--radius-sm);
  margin-bottom: 0.5rem;
  font-family: var(--font-family-mono);
}

.showcase-usage {
  font-size: var(--font-size-sm);
  color: var(--text-color-light);
}

/* Responsive */
@media (max-width: 768px) {
  .showcase-grid {
    grid-template-columns: 1fr;
  }
  
  .collapsible-header {
    padding: 1rem 1.5rem;
  }
  
  .collapsible-content {
    padding: 0 1.5rem 1.5rem;
  }
} 