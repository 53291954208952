/* ===== BUTTONS ===== */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  font-family: inherit;
  transition: all var(--transition-fast);
  cursor: pointer;
  border: none;
  gap: var(--spacing-sm);
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: visible;
}

/* Primary Button */
.btn-primary {
  background-color: var(--primary-color);
  color: var(--text-on-primary);
}

/* Styles spécifiques pour desktop */
@media (hover: hover) {
  .btn-primary:hover {
    background-color: var(--primary-light);
  }
}

/* Styles actifs pour tous les appareils */
.btn-primary:active {
  background-color: var(--primary-dark);
  transition: background-color 0.1s;
}

/* Secondary Button */
.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--text-on-secondary);
}

@media (hover: hover) {
  .btn-secondary:hover {
    background-color: var(--secondary-light);
  }
}

.btn-secondary:active {
  background-color: var(--secondary-dark);
  transition: background-color 0.1s;
}

/* Outline Button */
.btn-outline {
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

@media (hover: hover) {
  .btn-outline:hover {
    background-color: var(--neutral-light);
  }
}

.btn-outline:active {
  background-color: var(--neutral-color);
  transition: background-color 0.1s;
}

/* Ghost Button */
.btn-ghost {
  background-color: transparent;
  color: var(--text-primary);
}

@media (hover: hover) {
  .btn-ghost:hover {
    background-color: var(--neutral-light);
  }
}

.btn-ghost:active {
  background-color: var(--neutral-color);
  transition: background-color 0.1s;
}

/* États communs */
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-light);
}

/* Tailles */
.btn-sm {
  padding: var(--spacing-xs) var(--spacing-md);
  font-size: var(--font-size-xs);
}

.btn-lg {
  padding: var(--spacing-md) var(--spacing-xl);
  font-size: var(--font-size-md);
}

/* Danger Button */
.btn-danger {
  background-color: var(--secondary-color);
  color: white;
}

@media (hover: hover) {
  .btn-danger:hover {
    background-color: var(--secondary-light);
  }
}

.btn-danger:active {
  background-color: var(--secondary-dark);
  transition: background-color 0.1s;
}

/* Position */
.btn-bottom {
    margin-top: auto;
}

.btn .monkey-cursor {
    position: absolute;
    z-index: 1;
    overflow: visible;

}
