:root {
  /* Couleurs primitives */
  --color-green-100: #f3f9f7  ; 
  --color-green-200: #258E81 ;
  --color-green-300: #1D6E64 ;
  --color-green-400: #154F48;

  --color-red-200: #FA8962;
  --color-red-300: #C16A4C;
  --color-red-400: #874A35;

  --color-blue-200: #E8F0F6;  /* Très clair, pour fond subtil */
  --color-blue-300: #7D4F50;  /* Bleu pastel, pour hover */
  --color-blue-400: #6D99B3;  /* Bleu équilibré, pour contrastes */
 /* --color-blue-500: #486A7D; */ /* Bleu profond, pour texte et accents */


  --color-beige-100: #f2e9db;
  --color-beige-200: #e4d8c4;
  --color-beige-300: #d6c7ad;

  --color-gray-100: #f2f2f2;
  --color-gray-200: #e5e7e5;
  --color-gray-300: #717171;
  --color-gray-400: #4a4a4a;
  --color-gray-500: #2d2d2d;

  /* Couleurs de fond primitives */
  --color-background-primary: #f9f4ee;   /* Blanc cassé doux pour les yeux */
  --color-background-secondary: #f2e9db; /* Ton légèrement plus prononcé */
  --color-background-tertiary: #e4d8c4;  /* Le plus prononcé des fonds */
  --color-border: #d6c7ad;  
  --color-contrast: #cbbda7;            /* Bordure assortie */

  /* Espacements primitifs */
  --space-1: 0.25rem;  /* 4px */
  --space-2: 0.5rem;   /* 8px */
  --space-3: 0.75rem;  /* 12px */
  --space-4: 1rem;     /* 16px */
  --space-5: 1.5rem;   /* 24px */
  --space-6: 2rem;     /* 32px */

  /* Tailles primitives */
  --size-1: 0.25rem;   /* 4px */
  --size-2: 0.5rem;    /* 8px */
  --size-3: 0.75rem;   /* 12px */
  --size-4: 1rem;      /* 16px */
  --size-5: 1.5rem;    /* 24px */
  --size-6: 2rem;      /* 32px */

  /* Durées primitives */
  --duration-100: 100ms;
  --duration-200: 200ms;
  --duration-300: 300ms;
  --duration-400: 400ms;
  --duration-500: 500ms;

  /* Opacités primitives */
  --opacity-0: 0;
  --opacity-25: 0.25;
  --opacity-50: 0.5;
  --opacity-75: 0.75;
  --opacity-100: 1;
} 