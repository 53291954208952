

/* Responsive */
@media (max-width: 768px) {
    .color-grid, .spacing-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  
    .foundation-section {
      padding: var(--spacing-md);
    }
  }