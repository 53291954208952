.select-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.select-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.select-field {
  width: 100%;
  appearance: none;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  transition: var(--transition-fast);
  cursor: pointer;
  padding-right: calc(var(--input-padding) * 3);
  min-height: calc(var(--input-height) + 2px);
}

/* Tailles */
.select-sm {
  height: calc(var(--input-height) * 0.8);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-xs);
}

.select-md {
  height: var(--input-height);
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
}

.select-lg {
  height: calc(var(--input-height) * 1.2);
  padding: var(--spacing-md) var(--spacing-lg);
  font-size: var(--font-size-md);
}

.select-field:hover:not(:disabled) {
  border-color: var(--border-color-dark);
  background-color: color-mix(in srgb, var(--background-color) 99%, var(--primary-color));
}

.select-field:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

.select-field.has-error {
  border-color: var(--secondary-color);
}

.select-field.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

.select-field.is-disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.select-icon {
  position: absolute;
  right: var(--spacing-sm);
  color: var(--text-tertiary);
  pointer-events: none;
  transition: transform var(--transition-fast);
}

.select-field:focus + .select-icon {
  transform: rotate(180deg);
}

.select-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.select-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
}

/* Style des options */
.select-field option {
  padding: var(--spacing-sm) var(--spacing-md);
  color: var(--text-primary);
}

.select-field option:disabled {
  color: var(--text-tertiary);
}

.select-icon.rotated {
  transform: rotate(180deg);
} 