/* Mobile Layout Styles */
.mobile-layout {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--background-color);
}

.mobile-content {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
}

/* Toast Styles */
.mobile-toast {
  margin-bottom: env(safe-area-inset-bottom);
  border-radius: var(--radius-lg);
  font-size: 0.875rem;
  padding: var(--spacing-md) var(--spacing-lg);
  box-shadow: var(--shadow-lg);
}

/* Prevent pull-to-refresh on iOS, but allow overscroll in modals */
html:not(:has(.modal)), 
body:not(:has(.modal)) {
  overscroll-behavior-y: contain;
}

/* Hide scrollbar but keep functionality */
.mobile-layout {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.mobile-layout::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Prevent text selection */
.mobile-layout {
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
} 