.profile-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

/* Galerie */
.profile-header__gallery {
  width: 100%;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--background-secondary);
}

.profile-header__main-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.profile-header__gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
}

.profile-header__thumbnails {
  display: flex;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm);
  background-color: var(--background-secondary);
}

.profile-header__thumbnail {
  width: 60px;
  height: 60px;
  border-radius: var(--radius-sm);
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity var(--transition-normal);
}

.profile-header__thumbnail.active {
  opacity: 1;
  box-shadow: 0 0 0 2px var(--primary-color);
}

.profile-header__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Informations profil */
.profile-header__info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.profile-header__title-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.profile-header__avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--border-color);
}

.profile-header__avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-header__avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-tertiary);
}

.profile-header__title-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.profile-header__name {
  font-family: var(--font-family-headings);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.profile-header__subtitle {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
  margin: 0;
}

.profile-header__location {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.profile-header__description {
  font-size: var(--font-size-md);
  line-height: var(--line-height-relaxed);
  color: var(--text-secondary);
  margin: var(--spacing-md) 0;
}

.profile-header__tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-sm);
}

/* Contacts */
.profile-header__contacts-container {
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--border-color);
}

.profile-header__contacts-title {
  font-family: var(--font-family-headings);
  font-size: var(--font-size-md);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.profile-header__contacts-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.profile-header__contact-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  color: var(--text-secondary);
  text-decoration: none;
  padding: var(--spacing-xs) 0;
  transition: color var(--transition-normal);
}

.profile-header__contact-item:hover {
  color: var(--primary-color);
}

.profile-header__edit-button {
  align-self: flex-start;
  margin-top: var(--spacing-md);
}

/* Error states */
.profile-header--not-found,
.profile-header--error {
  text-align: center;
  padding: var(--spacing-xl);
}

/* Responsive design - Mobile first */
@media (max-width: 768px) {
  .profile-header__main-image {
    height: 200px;
  }
  
  .profile-header__thumbnail {
    width: 50px;
    height: 50px;
  }
  
  .profile-header__avatar {
    width: 60px;
    height: 60px;
  }
  
  .profile-header__name {
    font-size: var(--font-size-xl);
  }
}

/* Styles pour le carrousel d'images */
.profile-header__carousel {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  width: 100%;
  height: 100%;
  cursor: grab;
}

.profile-header__carousel::-webkit-scrollbar {
  display: none; /* Chrome/Safari */
}

.profile-header__carousel:active {
  cursor: grabbing;
}

.profile-header__carousel-item {
  flex: 0 0 100%;
  scroll-snap-align: start;
  width: 100%;
  height: 100%;
  position: relative; /* Pour positionner le bouton d'ouverture */
}

.profile-header__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Pour le cas où il n'y a pas d'images */
.profile-header__default-image-container {
  width: 100%;
  height: 100%;
}

/* Bouton pour ouvrir l'image en plein écran */
.profile-header__image-open-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color var(--transition-normal);
}

.profile-header__image-open-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Indicateurs du carrousel (points) */
.profile-header__indicators {
  position: absolute;
  bottom: var(--spacing-md);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
  z-index: 2;
}

.profile-header__indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0;
  cursor: pointer;
  transition: all var(--transition-normal);
}

.profile-header__indicator--active {
  background-color: var(--color-white);
  width: 10px;
  height: 10px;
}

/* Flèches de navigation (desktop uniquement) */
.profile-header__nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  cursor: pointer;
  z-index: 2;
  transition: background-color var(--transition-normal);
}

.profile-header__nav-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.profile-header__nav-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.profile-header__nav-arrow--prev {
  left: var(--spacing-md);
}

.profile-header__nav-arrow--next {
  right: var(--spacing-md);
}

/* Positionnement relatif pour la galerie pour les éléments de navigation */
.profile-header__gallery {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--background-secondary);
}

/* Media queries pour adapter la hauteur des images */
@media (max-width: 768px) {
  .profile-header__gallery {
    height: 250px;
  }
  
  .profile-header__nav-arrow {
    display: none;
  }
}

@media (max-width: 480px) {
  .profile-header__gallery {
    height: 200px;
  }
} 