.monkey-test {
  padding: var(--spacing-lg);
}

.monkey-test-grid {
  display: grid;
  gap: var(--spacing-lg);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.monkey-test-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
}

.monkey-test-label {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.monkey-test-svg {
  width: 200px;
  height: 200px;
}
