/* Conteneur de démo pour le MapDrawer */
.map-drawer-demo .demo-container {
  position: relative;
  min-height: 500px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  margin: var(--spacing-md) 0;
  overflow: hidden;
}

/* Position du drawer dans la démo */
.map-drawer-demo .demo-container .mapdrawer {
  position: absolute;
  bottom: var(--spacing-md);
  left: var(--spacing-md);
  right: var(--spacing-md);
  height: 400px;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.map-drawer-demo .demo-container .mapdrawer:not(.open) {
  transform: translateY(120%);
}

/* Styles des boutons de démo */
.map-drawer-demo .demo-container button {
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  background: var(--background-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-smooth);
}

.map-drawer-demo .demo-container button:hover {
  background: var(--background-tertiary);
  border-color: var(--border-color-hover);
}

/* Documentation */
.documentation pre {
  background: var(--background-secondary);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  overflow-x: auto;
}

.documentation code {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
}

.documentation ul {
  list-style-type: none;
  padding-left: 0;
}

.documentation li {
  margin: var(--spacing-xs) 0;
  color: var(--text-secondary);
} 