/* ===== TOGGLE SWITCH ===== */
.toggle-switch-container {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
  }
  
  .toggle-switch-container input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-tertiary);
    border: 2px solid var(--border-color);
    transition: all var(--transition-normal);
    border-radius: var(--radius-full);
  }
  
  .toggle-switch-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    transition: all var(--transition-spring);
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  /* État checked */
  .toggle-switch-container input:checked + .toggle-switch-slider {
    background-color: var(--primary-color);
    border-color: var(--primary-dark);
  }
  
  .toggle-switch-container input:checked + .toggle-switch-slider:before {
    transform: translate(24px, -50%);
  }
  
  /* État focus */
  .toggle-switch-container input:focus + .toggle-switch-slider {
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.2);
  }
  
  /* État disabled */
  .toggle-switch-container input:disabled + .toggle-switch-slider {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
  }

  .toggle-label {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
  }

  .toggle-group {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
  }