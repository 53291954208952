/* Base styles pour tous les drawers */
.drawer-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: calc(var(--z-drawer) - 1);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-fast);
}

.drawer-overlay--visible {
  opacity: 1;
  pointer-events: auto;
}

/* Conteneur - Gère le positionnement */
.drawer-container {
  position: fixed;
  bottom: calc(var(--bottom-nav-height) + max(env(safe-area-inset-bottom), 1rem) - var(--spacing-md));
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 500px;
  z-index: var(--z-drawer);
  pointer-events: none;
}

/* Drawer - Gère l'apparence et l'animation */
.drawer {
  width: 100%;
  background: var(--background-secondary);
  border-radius: var(--radius-xl) var(--radius-xl) var(--radius-lg) var(--radius-lg);
  padding: var(--spacing-lg) var(--spacing-md);
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
  pointer-events: auto;
  --drag-offset: 0px;
  /* État initial : en bas de l'écran */
  transform: translateY(100%);
  transition: none;
}

.drawer--ready {
  transition: transform var(--transition-normal),
              opacity var(--transition-normal),
              scale var(--transition-normal);
}

.drawer--open {
  transform: translateY(0);
}

.drawer--dragging {
  transition: none;
  transform: translateY(var(--drag-offset));
}

.drawer--closing {
  transform: translateY(100%);
  opacity: 0.95;
  scale: 0.98;
}

/* Header du drawer */
.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-lg);
}

.drawer-title {
  font-size: var(--text-xl);
  font-weight: var(--font-semibold);
  color: var(--text-primary);
  margin: 0;
}

/* Content */
.drawer-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

/* Footer avec boutons d'action */
.drawer-footer {
  margin-top: auto;
  padding: var(--spacing-md) 0 0;
}

.drawer-footer .action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
}

.drawer-footer .action-buttons button {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--text-md);
  font-weight: var(--font-medium);
}

.drawer-footer .action-buttons button[variant="secondary"] {
  background-color: var(--color-error-light);
  color: var(--color-error);
  border: none;
}

.drawer-footer .action-buttons button[variant="primary"] {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
}

/* Support des safe areas iOS */
@supports (padding: max(0px)) {
  .drawer {
    padding-bottom: max(var(--spacing-lg), calc(env(safe-area-inset-bottom) + var(--spacing-md)));
  }
}

/* Adaptation mobile */
@media (max-width: 768px) {
  .drawer-container {
    width: calc(100% - 1rem);
    bottom: calc(var(--bottom-nav-height) + 0.5rem - var(--spacing-md));
  }
  
  .drawer {
    max-height: 65vh;
  }
} 