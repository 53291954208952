.swipe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 16px;
  background: transparent;
  border-bottom: 1px solid var(--border-subtle);
  position: sticky;
  top: 0;
  z-index: 10;
}

.swipe-header__instructions {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-subtle);
  font-size: 14px;
 
}

.swipe-header__instruction {
  display: flex;
  align-items: center;
  gap: 4px;
}

.swipe-header__separator {
  color: var(--text-muted);
}

.swipe-header__undo {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding: 0 12px;
  border: none;
  background: var(--neutral-color);
  color: var(--neutral-text);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family-sans);
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-xs);
}

.swipe-header__undo:active {
  background: var(--neutral-surface-active);
  transform: translateY(0);
  box-shadow: var(--shadow-xs);
} 