.date-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--radius-md);
  overflow: hidden;
  font-family: var(--font-family-sans);
  background: var(--background-color);
  border: 1px solid var(--border-color);
}

/* Variantes */
.date-icon--light {
  background: var(--color-gray-100);
  border-color: var(--color-gray-200);
}

.date-icon--dark {
  background: var(--color-gray-800);
  border-color: var(--color-gray-700);
}

/* Tailles */
.date-icon--sm {
  width: 48px;
  height: 56px;
  font-size: var(--font-size-xs);
}

.date-icon--md {
  width: 56px;
  height: 64px;
  font-size: var(--font-size-sm);
}

.date-icon--lg {
  width: 64px;
  height: 72px;
  font-size: var(--font-size-md);
}

/* Éléments */
.date-icon__month {
  width: 100%;
  padding: var(--spacing-xs);
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  background: var(--primary-color);
  color: var(--text-on-primary);
}

.date-icon__day {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
} 