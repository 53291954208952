/* Item de détail */
.modal-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

/* Contenu principal */
.modal-item-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding-left: 0;
}

/* Label */
.modal-item-icon {
  flex-shrink: 0;
  color: var(--primary-color);
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Valeur */
.modal-item-value {
  flex: 1;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  line-height: var(--line-height-normal);
  padding-left: 0;
}

/* Action */
.modal-item-action {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-2);
}

/* Optimisations tactiles */
@media (hover: none) {
  .modal-item-action {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }
} 