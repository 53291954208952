/* Base Layout */
.design-system-container {
  padding: var(--spacing-lg);
  max-width: 1400px;
  margin: 0 auto;
  background-color: var(--background-color);
  min-height: 100vh;
}

/* Common Headings Styles */
.design-system-container h1,
.component-section h2,
.control-group h4,
.button-group h4 {
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-md) 0;
}

.design-system-container h1 {
  font-size: var(--font-size-3xl);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xl);
  text-align: center;
  position: relative;
  padding-bottom: var(--spacing-md);
}

.design-system-container h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: var(--background-secondary);
  border-radius: 2px;
}

/* Category Styles */
.design-system-category {
  margin-bottom: var(--spacing-xl);
  background: transparent;
  padding: 0 var(--spacing-md);
}

.category-title {
  font-size: var(--font-size-2xl);
  color: var(--text-primary);
  padding: var(--spacing-md) var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  background-color: var(--background-color);
  border-radius: var(--radius-lg);
  border-left: 4px solid var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.category-title::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: var(--spacing-md);
}

/* Section Styles */
.design-system-section {
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  margin-bottom: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-fast);
}

.design-system-section:hover {
  transform: translateY(-2px);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-md) var(--spacing-lg);
  cursor: pointer;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  background-color: var(--background-secondary);
  border: none;
  border-bottom: 1px solid var(--border-color);
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.section-header::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity var(--transition-fast);
}

.section-header:hover::before {
  opacity: 1;
}

.section-header:hover {
  background-color: var(--background-secondary);
}

.section-title {
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  margin: 0;
  font-weight: var(--font-weight-semibold);
  text-align: left;
}

.collapse-icon {
  color: var(--text-secondary);
  transition: transform var(--transition-normal);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--background-secondary);
}

.collapse-icon.collapsed {
  transform: rotate(-90deg);
}

.section-content {
  transition: all var(--transition-normal) cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
  overflow: hidden;
  opacity: 1;
}

.section-content.collapsed {
  max-height: 0;
  padding: 0;
  opacity: 0;
}

.section-content:not(.collapsed) {
  max-height: none;
  padding: var(--spacing-lg);
  opacity: 1;
}

/* Grid Layouts */
.component-content,
.button-type-container,
.control-type-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: var(--spacing-xl);
  padding: var(--spacing-lg);
}

/* Preview Sections */
.component-preview,
.button-row,
.control-preview {
  padding: var(--spacing-lg);
  background-color: var(--background-color);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
}

/* Button Styles */
.button-sizes {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.button-states {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
}

.button-disabled {
  margin-top: var(--spacing-lg);
}

/* Styles spécifiques pour la prévisualisation du bouton favori */
.button-row .favorite-button {
  position: static;
  margin: 0;
}

/* Toggle Switch Styles */
.toggle-demo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.button-row,
.control-preview {
  background-image: 
    linear-gradient(45deg, var(--background-secondary) 25%, transparent 25%),
    linear-gradient(-45deg, var(--background-secondary) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--background-secondary) 75%),
    linear-gradient(-45deg, transparent 75%, var(--background-secondary) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

/* Code Display */
.component-code,
.code-example {
  background-color: var(--background-tertiary);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
  padding: var(--spacing-md);
  overflow: hidden;
}

.code-example pre {
  margin: 0;
  padding: var(--spacing-md);
  background-color: var(--background-color);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
  overflow-x: auto;
}

/* Component Groups */
.button-group,
.control-group {
  background-color: var(--background-color);
  padding: var(--spacing-lg);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
}

/* Description Text */
.component-description,
.control-description {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: var(--spacing-xs) 0 var(--spacing-lg);
  line-height: var(--line-height-relaxed);
}

/* Hide redundant section titles */
.section-content h2 {
  display: none;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .component-content,
  .button-type-container,
  .control-type-container {
    grid-template-columns: 1fr;
  }
  
  .component-preview {
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }
  
  .component-code {
    position: static;
    max-height: none;
  }
}

@media (max-width: 768px) {
  .design-system-container {
    padding: var(--spacing-md);
  }

  .design-system-category {
    padding: 0;
  }

  .category-title {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .section-header {
    padding: var(--spacing-sm) var(--spacing-md);
  }
}