.organizer-page {
    min-height: 100vh;
    background-color: var(--background-color);
    position: relative;
  }
  
  .organizer-header {
    background-color: var(--background-secondary);
    border-bottom: 1px solid var(--border-color);
  }
  
  .header-content {
    max-width: var(--size-content-max);
    margin: 0 auto;
    padding: var(--spacing-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-left {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
  }
  
  .back-button {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--text-secondary);
    border: none;
    background: none;
    cursor: pointer;
    padding: var(--spacing-sm);
    border-radius: var(--radius-full);
    transition: background-color var(--transition-normal) var(--transition-timing-smooth);
  }
  
  .back-button:hover {
    background-color: var(--background-tertiary);
    color: var(--text-primary);
  }
  
  .header-actions {
    display: flex;
    gap: var(--spacing-sm);
  }
  
  .icon-button {
    padding: 0.5rem;
    border-radius: 9999px;
    border: none;
    background: none;
    cursor: pointer;
    color: #6b7280;
  }
  
  .icon-button:hover {
    background-color: #f3f4f6;
    color: #374151;
  }
  

  
  .organizer-content {
    max-width: var(--size-content-max);
    margin: 0 auto;
    padding: var(--spacing-md) var(--spacing-md);
    position: relative;
    padding-bottom: calc(var(--bottom-nav-height) + var(--spacing-lg));
  }
  
  .help-section {
    background-color: var(--color-beige-300);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .help-content {
    display: flex;
    flex-direction: column;
    
    
  }
  
  .help-text {
    flex: 1;
  }
  
  .help-text h3 {
    color: #1e40af;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .help-text ul {
    margin-top: 0.5rem;
    padding-left: 1.25rem;
    color: #1e40af;
  }
  
 
  
  .stats-grid {
    display: grid;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media screen and (max-width: 768px) {
    .stats-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 640px) {
    .stats-grid {
      grid-template-columns: 1fr;
      gap: var(--spacing-sm);
    }
  }
  
  .create-event-button {
    margin-bottom: var(--spacing-xs);
  }
  
  .tabs-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: var(--spacing-lg);
    border-bottom: 1px solid var(--border-color);
    margin-bottom: var(--spacing-lg);
  }
  
  .tab {
    position: relative;
    padding: var(--spacing-sm) var(--spacing-sm);
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    transition: color var(--transition-normal) var(--transition-timing-smooth);
  }
  
  .tab.active {
    color: var(--primary-color);
    font-weight: var(--font-weight-medium);
  }
  
  .sliding-bar {
    position: absolute;
    bottom: -1px;
    height: 2px;
    background-color: var(--primary-color);
    transition: all var(--transition-normal) var(--transition-timing-bounce);
  }
  
  .tab:hover {
    color: var(--primary-color);
  }

  .loading-container,
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  text-align: center;
  color: var(--text-secondary);
  font-size: var(--font-size-lg);
}

.error-container {
  color: var(--secondary-color);
}

/* Styles pour le formulaire d'événement */
.event-form-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-modal);
}

.event-form-content {
  background-color: var(--background-color);
  border-radius: var(--radius-lg);
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  padding: var(--spacing-xl);
  margin: var(--spacing-xl);
  box-shadow: var(--shadow-lg);
}

/* Styles pour la gestion des modales */
.modal-container {
  position: fixed;
  inset: 0;
  z-index: var(--z-modal);
  pointer-events: none;
}

.modal-container.active {
  pointer-events: auto;
}

.organizer-event-list-container {
  position: relative;
  z-index: 1;
}

/* Safe area pour les appareils mobiles */
@supports (padding: env(safe-area-inset-bottom)) {
  .organizer-page {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.stat-content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: var(--spacing-lg);
  width: 100%;
}

.stat-info {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.stat-value {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  line-height: 1;
  margin-bottom: 2px;
}

.stat-label {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: 1.2;
}

.stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.stat-icon svg {
  width: 20px;
  height: 20px;
}

/* La classe organizer-bottom-nav héritera automatiquement 
   des styles de .bottom-nav de BottomNav.css */

/* Position fixe pour le bouton Create Event */
.create-event-button-container {
  position: fixed;
  bottom: var(--bottom-nav-height);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  padding: var(--spacing-md);
  z-index: calc(var(--z-modal) - 1);
  pointer-events: none;
}

.create-event-button-container .create-event-button {
  pointer-events: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}