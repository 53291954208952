.file-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.file-input-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.file-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  border: 2px dashed var(--border-color);
  border-radius: var(--radius-lg);
  background: var(--background-secondary);
  cursor: pointer;
  transition: var(--transition-normal);
}

.file-input-container:hover:not(.is-disabled) {
  border-color: var(--primary-color);
  background: color-mix(in srgb, var(--primary-color) 5%, var(--background-secondary));
}

.file-input-container.is-dragging {
  border-color: var(--primary-color);
  background: color-mix(in srgb, var(--primary-color) 10%, var(--background-secondary));
}

.file-input-container.has-error {
  border-color: var(--secondary-color);
}

.file-input-container.is-disabled {
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.file-input-field {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-field:disabled {
  cursor: not-allowed;
}

.file-input-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  text-align: center;
}

.file-input-icon {
  color: var(--text-tertiary);
}

.file-input-text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.primary-text {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.secondary-text {
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
  text-decoration: underline;
}

.selected-files {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  word-break: break-all;
}

.file-input-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.file-input-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
} 