/* Variables locales pour le composant */
:root {
  --z-modal-overlay: 9000;
  --z-modal-content: 9002;
  --z-modal-hints: 9001; 
}

/* Overlay principal sans flou */
.base-modal__overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal-overlay);
}

/* Bande floue en haut */
.base-modal__overlay::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 75px; /* Hauteur de la bande floue */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(2px) brightness(1);
  -webkit-backdrop-filter: blur(4px) brightness(0.4);
  z-index: var(--z-modal-overlay);
}

/* Modal container */
.base-modal {
  position: fixed;
  inset: auto 0 var(--spacing-xl) 0; 
  z-index: var(--z-modal-content);
  overflow-y: auto;
  max-height: 85vh;
  }

/* Content container */
.base-modal__container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
} 

.swipe-hint {
  position: fixed;
  top: var(--spacing-sm);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing-lg);
  pointer-events: none;
  z-index: var(--z-modal-hints);
  
}

.swipe-hint__left,
.swipe-hint__right {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-lg);
opacity: 0.8
}

.swipe-hint h4 {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-lg);
  color: var(--text-on-primary);
  opacity: 0.7;
}

.swipe-hint__left svg,
.swipe-hint__right svg {
  color: var(--tertiary-color);
  opacity: 0.7;
}