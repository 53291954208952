/* Styles de base pour la structure du design system */
.foundation-section {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.foundation-section h2 {
  font-size: var(--font-size-2xl);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.foundation-section h3 {
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  margin: var(--spacing-xl) 0 var(--spacing-md);
}

.foundation-section h4 {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  margin: var(--spacing-lg) 0 var(--spacing-sm);
}

.foundation-section p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: var(--spacing-lg);
}
