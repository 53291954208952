/* Variables */
:root {
  --header-height: 64px;
}

/* Liste des événements */
.preview-card-map__list {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: var(--spacing-xs);
  padding-bottom: calc(var(--spacing-lg));
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  border-top-left-radius: var(--radius-xl);
  border-top-right-radius: var(--radius-xl);
  /* Permettre le scroll vertical tout en autorisant le drag quand au top */
  touch-action: pan-y;
  overscroll-behavior-y: contain;
}

/* Card */
.preview-card-map__card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  border-radius: var(--radius-lg);
}

.preview-card-map__card:active {
  transform: scale(0.98);
}

@media (hover: hover) {
  .preview-card-map__card:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }
}

.preview-card-map__tags {
  padding-bottom: var(--spacing-sm);
}

/* Conteneur principal pour l'image et le contenu */
.preview-card-map__main-content {
  display: flex;
  gap: var(--spacing-md);

}

/* Image fixe avec ratio 3:4 */
.preview-card-map__image {
  width: 120px; /* Taille fixe */
  flex-shrink: 0;
  overflow: hidden;
  border-radius: var(--radius-md);
  position: relative;
  z-index: 2; /* Valeur supérieure au z-index du ::before du MapDrawer */
}

.preview-card-map__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-card-map__card:hover .preview-card-map__image img {
  transform: scale(1.05);
}

.preview-card-map__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%; /* Pour s'assurer que le contenu prend toute la hauteur */
}

.preview-card-map__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-2xs);
}

.preview-card-map__top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.preview-card-map__image-fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-secondary);
  color: var(--text-tertiary);
}

.preview-card-map__image-fallback svg {
  width: 32px;
  height: 32px;
}

.preview-card-map__actions {
  display: flex;
  gap: var(--spacing-xs);
  margin-left: auto; /* Pousse les boutons à droite */
}

.preview-card-map__actions .share-button,
.preview-card-map__actions .favorite-button {
  width: 24px;
  height: 24px;
  padding: 0;
}

.preview-card-map__title-container {
  width: calc(100% - 80px); /* Largeur totale moins l'espace des boutons */
  margin: 0;
  padding: 0;
  margin-top: var(--spacing-sm);
}

.preview-card-map__title {
  margin: 0;
  padding-bottom: var(--spacing-sm);
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
  white-space: normal;
  overflow: hidden;
  line-height: 1.2;
  width: 100%;
}

.preview-card-map__details {
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.preview-card-map__date {
  font-weight: var(--font-weight-medium);
}

.preview-card-map__time {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
}

.preview-card-map__time::before {
  content: "•";
  margin: 0 var(--spacing-xs);
  color: var(--text-tertiary);
}

.preview-card-map__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Aligne les éléments avec le bas */
  margin-top: auto; /* Pousse le footer vers le bas */
}

/* Style pour le bouton Check details */
.preview-card-map__check-details {
  align-self: flex-end; /* Aligne le bouton avec le bas */
}

@media (max-width: 768px) {
  .preview-card-map__list {
    padding: 0 var(--spacing-sm);
    padding-bottom: var(--spacing-md);
  }

  .preview-card-map__image-fallback svg {
    width: 24px;
    height: 24px;
  }
}

/* Pour être encore plus spécifique */
.map-drawer .preview-card-map__image {
  position: relative;
  z-index: 2;
}

/* Et pour le logo lui-même */
.map-drawer .preview-card-map__image .logo-svg {
  position: relative;
  z-index: 2;
}

