.input-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.input-label {
  color: var(--text-primary);
  font-size: var(--input-font-size);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-xs);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  width: 100%;
  height: var(--input-height);
  padding: var(--input-padding-y) var(--input-padding-x);
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  color: var(--text-primary);
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  transition: all var(--transition-normal);
}

.input-field:hover:not(:disabled) {
  border-color: var(--border-color-dark);
  background-color: color-mix(in srgb, var(--background-color) 99%, var(--primary-color));
}

.input-field:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

.input-field.has-error {
  border-color: var(--secondary-color);
}

.input-field.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

.input-field.is-disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.input-field.has-icon {
  padding-left: calc(var(--input-padding-x) * 2.5);
}

.input-icon {
  position: absolute;
  left: var(--input-padding-x);
  color: var(--text-tertiary);
  pointer-events: none;
  font-size: var(--input-font-size);
}

.input-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xs);
}

.input-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-xs);
}

.input-reset-button {
  position: absolute;
  right: var(--input-padding-x);
  background: none;
  border: none;
  padding: var(--spacing-xs);
  cursor: pointer;
  color: var(--text-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color var(--transition-normal);
}

.input-reset-button:hover {
  color: var(--text-primary);
}

.input-field.has-reset {
  padding-right: calc(var(--input-padding-x) * 2.5);
} 