/* src/pages/DesignSystem/patterns/ContentBlock/ContentBlock.css */
.content-block {
  width: 100%;
}

.content-block--spacing-0 { margin: 0; }
.content-block--spacing-xs { margin: var(--spacing-xs) 0; }
.content-block--spacing-sm { margin: var(--spacing-sm) 0; }
.content-block--spacing-md { margin: var(--spacing-md) 0; }
.content-block--spacing-lg { margin: var(--spacing-lg) 0; }

.content-block--centered {
  margin-left: auto;
  margin-right: auto;
}

.content-block--sm { width: 320px; }
.content-block--md { width: 480px; }
.content-block--lg { width: 640px; }
.content-block--xl { width: 800px; }
.content-block--full { width: 100%; }
.content-block--auto { width: auto; }

.content-block__inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.content-block__inner--padding-xs { padding: var(--spacing-xs); }
.content-block__inner--padding-sm { padding: var(--spacing-sm); }
.content-block__inner--padding-md { padding: var(--spacing-md); }
.content-block__inner--padding-lg { padding: var(--spacing-lg); }
.content-block__inner--padding-0 { padding: 0; }

.content-block__header {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.content-block__icon {
  flex-shrink: 0;
  color: var(--text-primary);
}

.content-block__titles {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.content-block__title {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.content-block__subtitle {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
}

.content-block__content {
  width: 100%;
}

.content-block__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

.content-block__content--scrollable::-webkit-scrollbar {
  width: 4px;
}

.content-block__content--scrollable::-webkit-scrollbar-track {
  background: var(--surface-light);
}

.content-block__content--scrollable::-webkit-scrollbar-thumb {
  background: var(--surface-dark);
  border-radius: var(--radius-full);
}