.profile-events {
  width: 100%;
  margin-top: var(--spacing-lg);
}

.profile-events__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.profile-events__header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.profile-events__title {
  font-family: var(--font-family-headings);
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  margin: 0;
}

.profile-events__tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: var(--spacing-md);
}

.profile-events__tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  cursor: pointer;
  position: relative;
  transition: color var(--transition-normal);
}

.profile-events__tab.active {
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
}

.profile-events__tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

.profile-events__tab:hover {
  color: var(--primary-color);
}

.profile-events__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-md);
}

.profile-events__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-xl);
  color: var(--text-secondary);
}

.profile-events__loading svg {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.profile-events__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  color: var(--text-tertiary);
  text-align: center;
  gap: var(--spacing-md);
  background-color: var(--background-secondary);
  border-radius: var(--radius-md);
}

.profile-events__empty-subtitle {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Responsive design - Mobile first */
@media (min-width: 768px) {
  .profile-events__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-events__tabs {
    margin-bottom: 0;
  }
  
  .profile-events__grid {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
} 