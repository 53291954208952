/* Variables */
:root {
  --bottom-nav-height: 64px;
  --z-drawer: 900;
}

/* Overlay */
.map-drawer-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: calc(var(--z-drawer) - 1);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-fast);
}

.map-drawer-overlay--visible {
  opacity: 1;
  pointer-events: auto;
}

/* Conteneur - Gère le positionnement */
.map-drawer-container {
  position: fixed;
  bottom: calc(var(--bottom-nav-height) + max(env(safe-area-inset-bottom)));
  left: 50%;
  width: calc(100% - 2rem);
  max-width: 500px;
  z-index: var(--z-drawer);
  pointer-events: none;
  transform: translateX(-50%);
}

/* Drawer - Gère l'apparence et l'animation */
.map-drawer {
  width: 100%;
  max-height: 45vh;
  height: auto;
  background: var(--background-secondary);
  border-radius: var(--radius-xl) var(--radius-xl) var(--radius-lg) var(--radius-lg);
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: auto;
  --drag-offset: 0px;
  /* État initial : en bas de l'écran */
  transform: translateY(100%);
  transition: none;
}

.map-drawer--ready {
  transition: transform var(--transition-normal) var(--transition-timing-spring),
              opacity var(--transition-normal),
              scale var(--transition-normal);
}

.map-drawer--open {
  transform: translateY(0);
}

.map-drawer--dragging {
  transition: none;
  transform: translateY(var(--drag-offset));
}

.map-drawer--closing {
  transform: translateY(100%);
  opacity: 0.95;
  scale: 0.98;
}

/* En-tête */
.map-drawer__header {
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  background: var(--background-secondary);
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: var(--spacing-sm);
  /* Rendre l'en-tête draggable */
  cursor: grab;
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
}

.map-drawer__header:active {
  cursor: grabbing;
}

.map-drawer__header h2 {
  margin: 0;
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-tight);
}

.map-drawer__header p {
  margin: var(--spacing-2xs) 0 0;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
}

/* Styles spécifiques pour la démo */
.map-drawer-demo .map-drawer-container {
  position: absolute;
  z-index: 1;
}

/* Support des safe areas iOS */
@supports (padding: max(0px)) {
  .map-drawer {
    padding-bottom: max(var(--spacing-sm), env(safe-area-inset-bottom));
  }
}

/* Adaptation mobile */
@media (max-width: 768px) {
  .map-drawer-container {
    width: calc(100% - 1rem);
    bottom: calc(var(--bottom-nav-height) + 0.5rem - var(--spacing-md));
  }
} 