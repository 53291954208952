.logo-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--logo-size);
  height: var(--logo-size);
}

.logo-svg--auto {
  width: 100%;
  height: 100%;
}

.logo-svg__image {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.logo-svg__svg {
  width: var(--logo-size, 32px);
  height: var(--logo-size, 32px);
  color: inherit;
}

.logo-svg--auto .logo-svg__svg {
  width: 100%;
  height: 100%;
} 