.location-filter {
  width: 100%;
  padding: 0 var(--spacing-sm);
}

/* Forcer l'utilisation de nos styles de focus */
.location-filter .location-input:focus {
  outline: none !important;
  box-shadow: var(--focus-ring) !important;
  border-color: var(--primary-color) !important;
}

/* Style pour les suggestions d'autocomplétion de Google */
.pac-container {
  border-radius: var(--radius-md);
  margin-top: var(--spacing-xs);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-sm);
  background: var(--background-color);
  font-family: var(--font-family-sans);
  z-index: 1000;
}

.pac-item {
  padding: var(--spacing-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  border-bottom: 1px solid var(--border-color);
}

.pac-item:hover {
  background: var(--hover-color);
}

.pac-item-selected {
  background: var(--selected-color);
}

.pac-icon {
  width: 20px;
  height: 20px;
  margin-right: var(--spacing-sm);
}

.location-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px; /* Espace entre l'input et le bouton */
}

.location-input {
  flex: 1; /* Pour que l'input prenne tout l'espace disponible */
}

.location-geolocate-btn {
  flex-shrink: 0; /* Empêche le bouton de rétrécir */
  width: 36px;
  height: 36px;
  background: var(--background-secondary);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: var(--text-secondary);
}

.location-geolocate-btn:hover {
  background: var(--background-hover);
  color: var(--text-primary);
}

.location-geolocate-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.location-geolocate-btn.loading {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}  