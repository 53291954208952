.favorites {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: var(--background-color);
}

.favorites__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.favorites__list {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
  padding-bottom: calc(var(--spacing-md) + 80px); /* Espace pour le BottomNav */
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}


/* Support des safe areas iOS */
@supports (padding: max(0px)) {
  .favorites__list {
    padding-bottom: max(calc(var(--spacing-md) + 80px), calc(env(safe-area-inset-bottom) + var(--spacing-md) + 80px));
  }
}

.favorites__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 4rem;
  text-align: center;
  height: 50vh;
  color: var(--text-color-secondary);
}

.favorites__empty-state-icon {
  margin-bottom: 1.5rem;
  animation: float 3s ease-in-out infinite;
  height: 200px;
}

.favorites__empty-state h3 {
  margin: 0.5rem 0;
  color: var(--text-color-primary);
}

.favorites__empty-state p {
  line-height: 1.5;
}

/* Styles pour l'écran de chargement des favoris */
.favorites__loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  padding: 1rem;
  text-align: center;
}

.favorites__loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 24rem;
}

.favorites__loading-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0;
}

.favorites__loading-joke {
  font-size: 1rem;
  color: var(--text-color-secondary);
  line-height: 1.5;
  margin: 0 1rem;
}

.favorites__loading-logo {
  width: 120px;
  height: 120px;
  animation: pulse 2s infinite ease-in-out;
}

.favorites__loading-logo-image {
  width: 100%;
  height: 100%;
}

.favorites__loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(var(--primary-color-rgb), 0.2);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s linear infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 