.distance-slider {
  width: 100%;
  padding-top: var(--spacing-md);
  user-select: none;
  position: relative;
}

.distance-slider.disabled {
  opacity: var(--opacity-disabled);
  pointer-events: none;
}

/* Partie Fixe */
.fixed-elements {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
}

.distance-display {
  position: absolute;
  top: -4px;
  left: 10px;
  font-size: var(--font-size-sm);
  color: var(--text-on-primary);
  white-space: nowrap;
  background-color: var(--primary-light);
  padding: 2px var(--spacing-xs);
  border-radius: var(--radius-sm);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.user-circle {
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: var(--radius-full);
  background-color: var(--primary-color);
  z-index: 2;
}

/* Ligne de base (grise) */
.slider-line {
  position: absolute;
  left: 6px;
  right: 0;
  height: 4px;
  background-color: var(--background-tertiary);
  border-radius: var(--radius-full);
}

/* Ligne active (verte) */
.slider-line-active {
  position: absolute;
  left: 6px;
  height: 4px;
  background-color: var(--primary-light);
  width: calc(var(--slider-percent) - 6px);
  border-radius: var(--radius-full);
}

/* Partie Interactive */
.interactive-elements {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--spacing-md);
  height: 40px;
}

.slider-track {
  position: absolute;
  left: 6px;
  right: 0;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, calc(-50% - 12px));
  z-index: 3;
  cursor: pointer;
}

/* Ajustements pour le MapMarker */
.slider-handle .map-marker {
  color: var(--primary-color);
  transform: rotate(-45deg);
  position: relative;
  bottom: 0;
}

/* Support du focus */
.slider-handle:focus {
  outline: none;
}

.slider-handle:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
  border-radius: var(--radius-md);
}
