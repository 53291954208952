/* Admin Layout Styles */
.admin-layout {
  display: grid;
  grid-template-columns: 250px 1fr;
  min-height: 100vh;
  background-color: var(--background-color);
}

.admin-sidebar {
  background-color: var(--surface-color);
  border-right: 1px solid var(--border-color);
  padding: var(--spacing-lg);
  position: fixed;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
}

.admin-content {
  padding: var(--spacing-xl);
  margin-left: 250px;
  min-height: 100vh;
}

/* Toast Styles */
.admin-toast {
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  padding: var(--spacing-md) var(--spacing-lg);
  box-shadow: var(--shadow-md);
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-layout {
    display: flex;
    flex-direction: column;
  }

  .admin-sidebar {
    position: static;
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .admin-content {
    margin-left: 0;
    padding: var(--spacing-md);
  }
}

/* Scrollbar Styling */
.admin-sidebar::-webkit-scrollbar {
  width: 8px;
}

.admin-sidebar::-webkit-scrollbar-track {
  background: var(--surface-color);
}

.admin-sidebar::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
  border: 2px solid var(--surface-color);
} 