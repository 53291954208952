.textarea-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.textarea-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.textarea-field {
  width: 100%;
  min-height: calc(var(--input-height) * 2);
  padding: var(--input-padding);
  font-size: var(--font-size-md);
  color: var(--text-primary);
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  transition: var(--transition-fast);
  resize: vertical;
  font-family: inherit;
  line-height: var(--line-height-normal);
}

.textarea-field.auto-resize {
  resize: none;
  overflow-y: hidden;
}

.textarea-field:hover:not(:disabled) {
  border-color: var(--border-color-dark);
  background-color: color-mix(in srgb, var(--background-color) 99%, var(--primary-color));
}

.textarea-field:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

.textarea-field.has-error {
  border-color: var(--secondary-color);
}

.textarea-field.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

.textarea-field.is-disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.textarea-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.textarea-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
}

.textarea-counter {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  text-align: right;
  margin-top: calc(var(--spacing-xs) * -1);
} 