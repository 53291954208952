/* Spacing Styles */
.spacing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-xl);
  }
  
  .spacing-item {
    background: var(--background-color);
    border-radius: var(--radius-lg);
    padding: var(--spacing-md);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .spacing-preview {
    background: var(--background-secondary);
    border-radius: var(--radius-md);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spacing-box {
    background: var(--primary-color);
    border-radius: var(--radius-sm);
  }
  
  .semantic-spacing-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .semantic-spacing-item {
    background: var(--background-color);
    border-radius: var(--radius-lg);
    padding: var(--spacing-md);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .semantic-spacing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-sm);
  }
  
  .spacing-preview-horizontal {
    position: relative;
    height: 60px;
    background: var(--background-secondary);
    border-radius: var(--radius-md);
    margin: var(--spacing-sm) 0;
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-md);
  }
  
  .spacing-marker {
    width: 4px;
    height: 24px;
    background: var(--primary-color);
    border-radius: var(--radius-sm);
  }
  
  /* Base styles pour spacing-distance */
  .spacing-distance {
    height: 4px;
    background: var(--primary-color);
    margin: 0 var(--spacing-sm);
    position: relative;
  }
  
  /* Styles spécifiques pour chaque taille d'espacement */
  [data-spacing="sm"] .spacing-distance {
    width: var(--space-2);
  }
  
  [data-spacing="md"] .spacing-distance {
    width: var(--space-4);
  }
  
  [data-spacing="lg"] .spacing-distance {
    width: var(--space-5);
  }
  
  [data-spacing="xl"] .spacing-distance {
    width: var(--space-6);
  }
  
  .spacing-distance::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 20px;
    background: repeating-linear-gradient(
      90deg,
      var(--primary-color),
      var(--primary-color) 2px,
      transparent 2px,
      transparent 8px
    );
    opacity: 0.2;
  }
  
  .spacing-name {
    font-family: var(--font-family-mono);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
  }
  
  .spacing-value {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    font-family: var(--font-family-mono);
  }
  
  .spacing-description {
    color: var(--text-tertiary);
    font-size: var(--font-size-sm);
    margin-top: var(--spacing-sm);
  }