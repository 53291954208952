.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-container .back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    transition: color 0.2s;
  }
  
  .login-container .back-button:hover {
    color: #1f2937;
    background: none;
  }

  .login-container .login-card {
    position: relative;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-container .login-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .login-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .login-container .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .login-container .form-group input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .login-container .login-form button,
  .login-container .google-button {
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .login-container .login-form button:hover,
  .login-container .google-button:hover {
    background-color: #0056b3;
  }
  
  .login-container .login-form button:disabled,
  .login-container .google-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }

  /* ... styles existants ... */

.login-container .auth-links {
    margin-top: 1.5rem;
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .login-container .auth-links p {
    color: #6b7280;
    margin-bottom: 0.5rem;
  }
  
  .login-container .register-link {
    color: #4f46e5;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .login-container .register-link:hover {
    color: #4338ca;
    text-decoration: underline;
  }

  
.login-container .separator {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.login-container .separator::before,
.login-container .separator::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e5e7eb;
}

.login-container .separator::before {
  left: 0;
}

.login-container .separator::after {
  right: 0;
}

.login-container .google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #374151;
  font-weight: 500;
  transition: all 0.2s;
}

.login-container .google-button:hover {
  background-color: #f9fafb;
  border-color: #d1d5db;
}

.login-container .google-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-container .google-button svg {
  width: 18px;
  height: 18px;
}

.login-container .forgot-password-button {
  background: none !important;
  border: none !important;
  color: #4F46E5 !important;
  padding: 0 !important;
  font-size: 0.875rem !important;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  transition: none !important;
  background-color: transparent !important;
}
