.menu-drawer {
  --menu-drawer-width: calc(100% - 2rem);
  width: var(--menu-drawer-width);
  font-family: var(--font-family-sans);
  max-width: 500px;
}

.menu-drawer-header {
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
}

.menu-drawer-header h2 {
  margin: 0;
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  color: var(--text-primary);
  font-family: var(--font-family-sans);
}

.menu-drawer-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-lg);
  font-family: var(--font-family-sans);
}

.menu-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background: none;
  border: none;
  border-radius: var(--radius-lg);
  color: var(--text-primary);
  font-size: var(--text-md);
  font-weight: var(--font-medium);
  font-family: var(--font-family-sans);
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.menu-item:hover {
  background-color: var(--background-hover);
  transform: translateX(4px);
}

.menu-item:active {
  transform: translateX(2px);
}

.menu-item svg {
  color: var(--color-primary);
  flex-shrink: 0;
  transition: transform var(--transition-fast);
}

.menu-item:hover svg {
  transform: scale(1.1);
}

.menu-item span {
  flex-grow: 1;
  font-family: var(--font-family-sans);
}

.menu-item--highlight {
  background: var(--color-green-100);
  color: var(--color-green-300);
  font-weight: 500;
  position: relative;
}

.menu-item--highlight::after {
  content: 'New';
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 0.75rem;
  padding: 2px 6px;
  background: var(--color-green-300);
  color: white;
  border-radius: 12px;
}

@media (min-width: 768px) {
  .menu-drawer {
    --menu-drawer-width: 400px;
  }
}

@media (max-width: 768px) {
  .menu-drawer {
    width: calc(100% - 1rem);
  }
}

.contact-modal {
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
}

.contact-modal__content {
  padding: var(--spacing-xl);
  text-align: center;
}

.contact-modal__title {
  margin-bottom: var(--spacing-lg);
  font-family: var(--font-family-headings);
  font-size: var(--font-size-xl);
  color: var(--text-primary);
}

.contact-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.contact-modal__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-md);
  border: none;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: transform var(--transition-fast) var(--transition-timing-bounce);
}

.contact-modal__button:active {
  transform: scale(var(--scale-active));
}

.contact-modal__button--email {
  background-color: var(--primary-color);
  color: var(--text-on-primary);
}

.contact-modal__button--feedback {
  background-color: var(--secondary-color);
  color: var(--text-on-secondary);
}

.menu-drawer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.menu-drawer-modal__content {
  background: white;
  padding: var(--spacing-xl);
  border-radius: var(--radius-lg);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.menu-drawer-modal__title {
  margin-bottom: var(--spacing-lg);
  font-family: var(--font-family-headings);
  font-size: var(--font-size-xl);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.menu-drawer-modal__title .logo-svg__image {
  transform: scaleX(-1);
}

.menu-drawer-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.menu-drawer-modal__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  border: none;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
}

.menu-drawer-modal__button--email {
  background-color: var(--primary-color);
  color: white;
}

.menu-drawer-modal__button--feedback {
  background-color: var(--secondary-color);
  color: white;
}

.menu-drawer-error {
  padding: var(--spacing-sm);
  margin: var(--spacing-xs);
  background-color: var(--secondary-light);
  color: var(--text-on-secondary);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  text-align: center;
  font-family: var(--font-family-sans);
}

.menu-item--loading {
  opacity: var(--opacity-disabled);
  cursor: wait;
  transition: opacity var(--transition-normal) var(--transition-timing-smooth);
}

.menu-item__spinner {
  width: var(--size-4);
  height: var(--size-4);
  margin-left: var(--spacing-xs);
  border: 2px solid color-mix(in srgb, var(--text-on-primary) 30%, transparent);
  border-top-color: var(--text-on-primary);
  border-radius: var(--radius-full);
  animation: spin var(--transition-normal) linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Amélioration de l'accessibilité tactile sur mobile */
@media (max-width: 768px) {
  .menu-item {
    min-height: 44px; /* Taille minimale recommandée pour les cibles tactiles */
    padding: var(--spacing-sm) var(--spacing-md);
    transition: background-color var(--transition-normal) var(--transition-timing-smooth);
  }

  .menu-item:active {
    transform: scale(var(--scale-active));
  }
}