/* Base styles */
.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color) !important;
  border: none;
  border-radius: var(--radius-full);
  cursor: pointer;
  transition: all var(--transition-normal);
  color: var(--primary-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

/* Hover state - uniquement sur desktop */
@media (hover: hover) and (pointer: fine) {
  .share-button:hover {
    background: var(--primary-light) !important;
    color: var(--background-color);
    transform: scale(var(--scale-hover));
  }
}

/* Mobile specific rules */
@media (hover: none) {
  .share-button {
    background: var(--background-color) !important;
    color: var(--primary-color) !important;
  }
}

/* Focus state */
.share-button:focus {
  outline: none;
}

.share-button:focus-visible {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

/* Sizes - Desktop */
@media (min-width: 769px) {
  .share-button-sm {
    width: 32px !important;
    height: 32px !important;
  }

  .share-button-sm svg {
    width: 16px !important;
    height: 16px !important;
  }

  .share-button-md {
    width: 40px !important;
    height: 40px !important;
  }

  .share-button-md svg {
    width: 20px !important;
    height: 20px !important;
  }

  .share-button-lg {
    width: 48px !important;
    height: 48px !important;
  }

  .share-button-lg svg {
    width: 24px !important;
    height: 24px !important;
  }
}

/* Responsive styles - Mobile */
@media (max-width: 768px) {
  .share-button-sm {
    width: 24px !important;
    height: 24px !important;
  }

  .share-button-sm svg {
    width: 12px !important;
    height: 12px !important;
  }

  .share-button-md {
    width: 32px !important;
    height: 32px !important;
  }

  .share-button-md svg {
    width: 16px !important;
    height: 16px !important;
  }

  .share-button-lg {
    width: 40px !important;
    height: 40px !important;
  }

  .share-button-lg svg {
    width: 20px !important;
    height: 20px !important;
  }
} 