/* Conteneur principal */
.currency-input-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

/* Label */
.currency-input-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

/* Wrapper principal */
.currency-input-wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
  align-items: flex-start;
}

/* Wrapper du montant */
.currency-amount-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;
  min-width: 120px;
}

.currency-amount-input {
  width: 100%;
  height: var(--input-height);
  padding: var(--input-padding-y) var(--input-padding-x);
  padding-right: calc(var(--input-padding-x) + 24px); /* Espace pour le symbole */
  font-size: var(--input-font-size);
  color: var(--text-primary);
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  transition: var(--transition-normal);
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Masquer les flèches du input number */
.currency-amount-input::-webkit-outer-spin-button,
.currency-amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.currency-amount-input:hover:not(:disabled) {
  border-color: var(--border-color-dark);
  background-color: color-mix(in srgb, var(--background-color) 99%, var(--primary-color));
}

.currency-amount-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

.currency-symbol {
  position: absolute;
  right: var(--input-padding-x);
  color: var(--text-tertiary);
  pointer-events: none;
  font-size: var(--font-size-sm);
}

/* États d'erreur */
.currency-amount-input.has-error {
  border-color: var(--secondary-color);
}

.currency-amount-input.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

/* États désactivés */
.currency-amount-input:disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

/* Textes d'aide et d'erreur */
.currency-input-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.currency-input-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
}

/* Style pour le Select de devise */
.currency-input-wrapper > select {
  flex: 1;
  min-width: 150px;
}

.currency-select-option {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.currency-select-option img {
  width: 24px;
  height: 18px;
  object-fit: cover;
}

.currency-select-group {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  padding: var(--spacing-xs) var(--spacing-sm);
}