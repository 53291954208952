/* Base marker styles */
.map-marker {
  position: relative;
  width: 24px;
  height: 24px;
  background: var(--primary-color);
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-md);
}

/* Marker states */
.marker-selected {
  background: var(--primary-light);
  z-index: 2;
  position: relative;
}

.marker-favorite {
  background: var(--secondary-color);
  position: relative;
}

.marker-cluster {
  background: var(--primary-light);
  color: var(--primary-color);
}

/* Pulse animation for selected marker */
.marker-selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50% 50% 50% 0;
  background: inherit;
  opacity: 0.4;
  z-index: -1;
  animation: pulse 2s infinite;
  transform: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
}

/* Inner content container (for logo or number) */
.marker-content {
  transform: rotate(45deg); /* Counter-rotate to keep content straight */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

/* Marker sizes */
.marker-sm {
  width: 16px;
  height: 16px;
}

.marker-sm .marker-content {
  width: 20px;
  height: 20px;
  font-size: var(--font-size-xs);
}

.marker-lg {
  width: 32px;
  height: 32px;
}

.marker-lg .marker-content {
  width: 32px;
  height: 32px;
  font-size: var(--font-size-md);
}

.marker-cluster .marker-content {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

/* Logo placeholder styles */
.marker-logo-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  font-size: var(--font-size-xs);
  text-align: center;
  line-height: 1.2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Taille par défaut uniquement */
  .map-marker:not(.marker-sm):not(.marker-lg) {
    width: 24px;
    height: 24px;
  }

  .map-marker:not(.marker-sm):not(.marker-lg) .marker-content {
    width: 20px;
    height: 20px;
    font-size: var(--font-size-xs);
  }

  /* Petite taille */
  .marker-sm {
    width: 16px;
    height: 16px;
  }

  .marker-sm .marker-content {
    width: 16px;
    height: 16px;
    font-size: var(--font-size-xs);
  }

  /* Grande taille */
  .marker-lg {
    width: 32px;
    height: 32px;
  }

  .marker-lg .marker-content {
    width: 24px;
    height: 24px;
    font-size: var(--font-size-sm);
  }
} 