/* Conteneur principal de la carte */
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  touch-action: manipulation;
}

/* Indicateur de chargement */
.map-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px;
}

/* Désactiver les transitions pendant le zoom et le déplacement */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-zoom-anim .leaflet-zoom-animated {
  transition: none !important;
}

/* Optimisations mobiles */
@media (max-width: 768px) {
  .map-container {
    height: calc(100vh - 60px);
  }
}

/* Modifier le style du conteneur empty state */
.map-empty-state {
  position: absolute;
  top: 25vh; /* Un peu d'espace en haut */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%; /* Responsive mais pas full width */
  max-width: 400px; /* Limite la largeur max */
}

/* Supprimer les styles qui ne sont plus nécessaires 
.map-empty-state-content,
.map-empty-state h3 {
  /* Ces styles peuvent être supprimés car gérés par ContentBlock */





.map-empty-state .content-block__subtitle {
  font-family: var(--font-family-sans) !important;
}




