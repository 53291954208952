@import '../../styles/design-tokens.css';

/*
 * 1. Structure de base
 * ------------------- */
.event-card {
  position: relative;
  background: var(--background-secondary);
  border-radius: var(--radius-md);
  overflow: hidden;
  border: 1px solid var(--border-color);
  padding: var(--spacing-md);
  width: 100%;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  max-width: 650px;
  margin: 0 auto;
}

/* 
 * 2. Header 
 * -------- */
.event-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  padding-bottom: var(--spacing-md);
}

.event-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  
}

.event-card__actions {
  display: flex;
  gap: var(--spacing-sm);
  pointer-events: auto;
}

/*
 * 3. Main Content
 * -------------- */
.event-card__main {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 2fr;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

/* Image */
.event-card__image {
  position: relative;
  height: 100%;
  
  border-radius: var(--radius-md);
  overflow: hidden;
  background-color: var(--background-tertiary);
  margin-top: auto;
  align-self: flex-end;
}

.event-card__image img,
.event-card__image .event-fallback-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}



/* Info */
.event-card__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; 
}

.event-card__title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
  align-self: flex-start;
  padding-bottom: var(--spacing-md);
}

.event-card__datetime {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.event-card__time-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.event-card__time-info .day {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.event-card__time-info .hours {
  color: var(--text-secondary);
}

/*
 * 4. Footer et Location
 * -------------------- */
.event-card__footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.event-card__location {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.event-card__location-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--text-primary);
  flex-shrink: 0;
}

.event-card__location-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  flex: 1;
  min-width: 0;
  padding: var(--spacing-sm) 0;
  position: relative;
}

.event-card__location-content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  background-color: var(--border-color);
}

.event-card__location-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  flex: 1;
  min-width: 0;
  padding-left: var(--spacing-md);
}

.event-card__location-name {
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
}

.event-card__location-distance {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.event-card__location-button {
  flex-shrink: 0;
}

/*
 * 5. Événements récurrents
 * ----------------------- */
.event-card__dates {
  position: relative;
  z-index: 2;
  pointer-events: auto;
}

.event-card__dates-toggle {
  width: 100%;
  min-width: 150px;
  margin: 0 auto;
  padding: var(--spacing-2xs) var(--spacing-xs);
  background: var(--surface-light);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-md);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  pointer-events: auto;
}

.event-card__dates-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-normal);
}

.event-card__dates-list--expanded {
  max-height: 300px;
  overflow-y: auto;
}

/*
 * 6. Swipe et Animations
 * --------------------- */
.card {
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.2s ease-out;
}

.card.swiping {
  transition: none;
}

/*
 * 7. États et Fallbacks
 * -------------------- */
.event-card.no-image {
  max-height: fit-content;
  margin-top: 0;
}

.event-card .no-image-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  background: var(--background-secondary);
  margin: 0;
}

.event-card .no-image-header .no-image-tag-container {
  padding: 0;
  width: 100%;
}

.event-card .no-image-header .price-tag-wrapper .tag[type="price"] {
  position: static;
}

.event-card .no-image-header .activity-tags-wrapper {
  flex: 1;
}

.event-card.no-image .event-tags {
  margin: 0;
  padding: 0;
}

.event-card.no-image .event-content {
  padding-top: var(--spacing-sm);
}

/* Vue liste */
.home__list-view .event-card {
  height: fit-content;
  min-height: min-content;
  flex: 0 0 auto;
 
}



/* Desktop */
@media (min-width: 768px) {
  .home__list-view .event-card {
       box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .home__list-view .event-card:hover {
    transform: translateY(-2px);
  }

  .event-card:active {
    transform: none;
  }
} 

/* Responsive */
@media (max-width: 768px) {
  .event-card__main {
    grid-template-columns: minmax(0, 1fr) 2fr;
    gap: var(--spacing-md);
  }

 

 
}

/* Animation states */
.event-card:hover {
  transform: translateY(-2px);
}

@media (hover: none) {
  .event-card:hover {
    transform: none;
  }
}

