/* Base styles pour le bouton rond */
.favorite-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  border: none;
  border-radius: var(--radius-full);
  cursor: pointer;
  transition: all 0.1s ease-out;
  color: var(--secondary-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

/* Active state pour le bouton rond */
.favorite-button.active {
  background: var(--secondary-color) !important;
  color: var(--background-color) !important;
  transition: all 0.1s ease-out;
}

/* Styles spécifiques pour le bouton standard en variant button */
.btn.favorite-button-variant {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.btn.favorite-button-variant.active {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-on-primary) !important;
}

/* Hover state - uniquement sur desktop */
@media (hover: hover) and (pointer: fine) {
  .favorite-button:hover:not(.active) {
    background: var(--secondary-light) !important;
    color: var(--background-color);
    transform: scale(var(--scale-hover));
  }
}

/* Mobile specific rules */
@media (hover: none) {
  .favorite-button:active:not(.active) {
    background: var(--secondary-light) !important;
    color: var(--background-color) !important;
    transition: none;
  }
  
  .favorite-button.active {
    transition: none;
  }

  .btn.favorite-button-variant:active {
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                color 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

/* Focus state */
.favorite-button:focus {
  outline: none;
}

.favorite-button:focus-visible {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

/* Sizes - Desktop */
@media (min-width: 769px) {
  .favorite-button-sm {
    width: 32px !important;
    height: 32px !important;
  }

  .favorite-button-sm svg {
    width: 16px !important;
    height: 16px !important;
  }

  .favorite-button-md {
    width: 40px !important;
    height: 40px !important;
  }

  .favorite-button-md svg {
    width: 20px !important;
    height: 20px !important;
  }

  .favorite-button-lg {
    width: 48px !important;
    height: 48px !important;
  }

  .favorite-button-lg svg {
    width: 24px !important;
    height: 24px !important;
  }
}

/* Responsive styles - Mobile */
@media (max-width: 768px) {
  /* Petite taille */
  .favorite-button-sm {
    width: 24px !important;
    height: 24px !important;
  }

  .favorite-button-sm svg {
    width: 12px !important;
    height: 12px !important;
  }

  /* Taille moyenne */
  .favorite-button-md {
    width: 32px !important;
    height: 32px !important;
  }

  .favorite-button-md svg {
    width: 16px !important;
    height: 16px !important;
  }

  /* Grande taille */
  .favorite-button-lg {
    width: 40px !important;
    height: 40px !important;
  }

  .favorite-button-lg svg {
    width: 20px !important;
    height: 20px !important;
  }
} 