.number-input-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: fit-content;
  min-width: 120px;
  max-width: 120px;
}

.number-input-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

.number-input-wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--border-color);
  transition: var(--transition-fast);
  overflow: hidden;
}

.number-input-wrapper:hover:not(.is-disabled) {
  border-color: var(--border-color-dark);
}

.number-input-wrapper:focus-within {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

.number-input-field {
  width: 60px;
  height: var(--input-height);
  padding: 0;
  font-size: var(--font-size-md);
  color: var(--text-primary);
  background: var(--input-background);
  border: none;
  text-align: center;
  appearance: textfield;
  -moz-appearance: textfield; /* Firefox */
}

/* Masquer les flèches par défaut */
.number-input-field::-webkit-outer-spin-button,
.number-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: var(--input-height);
  padding: 0;
  border: none;
  background: var(--background-secondary);
  color: var(--text-primary);
  cursor: pointer;
  transition: var(--transition-fast);
}

.number-input-button:hover:not(:disabled) {
  background: var(--background-tertiary);
  color: var(--primary-color);
}

.number-input-button:active:not(:disabled) {
  background: var(--background-tertiary);
  transform: scale(0.95);
}

.number-input-button:disabled {
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.number-input-field.has-error {
  border-color: var(--secondary-color);
}

.number-input-field.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

.number-input-field.is-disabled,
.number-input-wrapper.is-disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

.number-input-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.number-input-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
} 