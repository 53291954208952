/* Fil d'ariane moderne */
.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing-xl) auto;
  max-width: 600px;
  position: relative;
  padding: 0 var(--spacing-lg);
}

/* Ligne de base (grise) */
.step-indicator::before {
  content: '';
  position: absolute;
  top: 28px;
  left: 60px;
  right: 60px;
  height: 3px;
  background: var(--neutral-light);
  z-index: 1;
}

/* Ligne de progression (verte) */
.step-indicator::after {
  content: '';
  position: absolute;
  top: 28px;
  left: 60px;
  height: 3px;
  background: var(--primary-color);
  z-index: 2;
  transition: width var(--transition-normal) ease-in-out;
}

/* États des étapes */
.step-item.completed .step-circle {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--background-color);
}

.step-item.completed .step-circle::after {
    content: none; /* Supprime l'icône check */
}

.step-item.completed .step-number {
    color: var(--background-color);
    display: block;
}

.step-item.active .step-circle {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--background-color);
    transform: scale(1.1);
}

.step-item.active .step-circle::before {
    background: rgba(3, 97, 71, 0.1);
}

.step-item.active .step-label {
    color: var(--primary-color);
}

/* Gestion de la progression */
.step-indicator[data-step="0"]::after {
    width: 0;
}

.step-indicator[data-step="1"]::after {
    width: calc(50% - 60px);
}

.step-indicator[data-step="2"]::after {
    width: calc(100% - 120px);
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  flex: 1;
  max-width: 120px;
}

.step-circle {
  width: 56px;
  height: 56px;
  border-radius: var(--radius-full);
  background: var(--background-color);
  border: 3px solid var(--neutral-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-sm);
  position: relative;
  transition: all var(--transition-normal);
  font-weight: 600;
  color: var(--text-secondary);
}

/* Effet de halo autour du cercle actif */
.step-circle::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: var(--radius-full);
  background: rgba(3, 97, 71, 0);
  transition: all var(--transition-normal);
}

.step-content {
  text-align: center;
  padding: 0 var(--spacing-xs);
}

.step-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
  transition: all var(--transition-normal);
}

.step-description {
  font-size: 0.8rem;
  color: var(--text-secondary);
  transition: all var(--transition-normal);
}

/* États actifs des textes */
.step-item.active .step-description {
  color: var(--primary-light);
}

/* Animation d'entrée */
@keyframes stepAppear {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.step-item {
  animation: stepAppear var(--transition-normal) ease-out forwards;
}