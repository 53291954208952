/* Styles communs pour les conteneurs */
.date-input-container,
.time-input-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: fit-content;
}

/* Styles communs pour les labels */
.date-input-label,
.time-input-label {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.required-mark {
  color: var(--secondary-color);
  margin-left: var(--spacing-xs);
}

/* Styles communs pour les wrappers */
.date-input-wrapper,
.time-input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: auto;
}

/* Styles communs pour les champs */
.date-input-field,
.time-input-field {
  width: auto;
  height: var(--input-height);
  padding: var(--input-padding-y) var(--input-padding-x);
  font-size: var(--input-font-size);
  color: var(--text-primary);
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  transition: var(--transition-normal);
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}

/* Ajustement spécifique pour le champ date */
.date-input-field {
  min-width: 120px; /* Pour accommoder YYYY-MM-DD */
  padding-right: var(--input-padding-x);
}

/* Style de l'icône native du calendrier */
.date-input-field::-webkit-calendar-picker-indicator {
  opacity: 0.6;
  cursor: pointer;
  transition: opacity var(--transition-fast);
  margin-left: 0;
  padding-left: 0;
}

.date-input-field::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* Ajustement spécifique pour le champ time */
.time-input-field {
  min-width: 85px; /* Pour accommoder HH:MM */
  padding-right: var(--input-padding-x);
}

/* Style de l'icône native du time picker */
.time-input-field::-webkit-calendar-picker-indicator {
  opacity: 0.6;
  cursor: pointer;
  transition: opacity var(--transition-fast);
  margin-left: 0;
  padding-left: 0;
}

.time-input-field::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* Masquer les contrôles spin du time input */
.time-input-field::-webkit-inner-spin-button,
.time-input-field::-webkit-clear-button {
  display: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  opacity: 0 !important;
  margin: 0;
}

/* Effets de hover */
.date-input-field:hover:not(:disabled),
.time-input-field:hover:not(:disabled) {
  border-color: var(--border-color-dark);
  background-color: color-mix(in srgb, var(--background-color) 99%, var(--primary-color));
}

/* États de focus */
.date-input-field:focus,
.time-input-field:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--primary-color) 15%, transparent);
}

/* États d'erreur */
.date-input-field.has-error,
.time-input-field.has-error {
  border-color: var(--secondary-color);
}

.date-input-field.has-error:focus,
.time-input-field.has-error:focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--secondary-color) 15%, transparent);
}

/* États désactivés */
.date-input-field.is-disabled,
.time-input-field.is-disabled {
  background-color: var(--background-secondary);
  cursor: not-allowed;
  opacity: var(--opacity-disabled);
}

/* Styles communs pour les textes d'aide */
.date-input-helper,
.time-input-helper {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xs);
}

/* Styles communs pour les messages d'erreur */
.date-input-error,
.time-input-error {
  font-size: var(--font-size-xs);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-xs);
} 