.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: var(--background-color);
}

.home__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.home__views {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.home__list-view,
.home__map-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-normal), transform var(--transition-normal);
}

.home__list-view {
  transform: translate3d(100%, 0, 0);
}

.home__map-view {
  transform: translate3d(-100%, 0, 0);
}

.home__list-view--active,
.home__map-view--active {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
}

.home__events-grid {
  display: grid;
  /* Sur mobile : une seule colonne */
  grid-template-columns: 1fr;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  overflow-y: auto;
  height: 100%;
  padding-bottom: calc(var(--spacing-md) + 80px); /* Espace pour le BottomNav */
}

/* Sur desktop (> 768px) : grille auto-fill */
@media (min-width: 768px) {
  .home__events-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

/* Support des safe areas iOS */
@supports (padding: max(0px)) {
  .home__events-grid {
    padding-bottom: max(calc(var(--spacing-md) + 80px), calc(env(safe-area-inset-bottom) + var(--spacing-md) + 80px));
  }
}

/* Animation pour l'ouverture de la modale */
.modal-opening {
  pointer-events: none;
}

.modal-opening::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: fadeIn 300ms ease-out forwards;
  z-index: 1000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  height: 50vh;
  color: var(--text-color-secondary);
}

.home__empty-state-icon {
  margin-bottom: 1.5rem;
  animation: float 3s ease-in-out infinite;
  height: 200px;
}

.home__empty-state h3 {
  margin: 0.5rem 0;
  color: var(--text-color-primary);
}

.home__empty-state p {
  line-height: 1.5;
}
